import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EnderecoTipo} from '@app/abstraction/domain/interfaces/shared/endereco-tipo.interface';
import {API} from '@app/shared/constants/api.constant';
import {lastValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EnderecoTipoService {
  constructor(private client: HttpClient) {}
  buscarTodos() {
    const response$ = this.client.get<EnderecoTipo[]>(`${API.ENDERECO_TIPOS}`);
    return lastValueFrom(response$);
  }
}
