import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fone',
})
export class FonePipe implements PipeTransform {
  transform(value: string | number): string | void {
    if (value) {
      const fone = value.toString();
      if (fone.startsWith('0800')) {
        return this.foneFormat0800(fone);
      } else {
        if (fone.length <= 10) {
          return this.foneFormat10(fone);
        } else {
          return this.foneFormat11(fone);
        }
      }
    }
    return '';
  }
  foneFormat0800(fone: string): string {
    return `${fone.slice(0, 4)} ${fone.slice(4, 7)} ${fone.slice(7, 11)}`;
  }
  foneFormat11(fone: string): string {
    return `(${fone.slice(0, 2)}) ${fone.slice(2, 7)}-${fone.slice(7, 11)}`;
  }
  foneFormat10(fone: string): string {
    return `(${fone.slice(0, 2)}) ${fone.slice(2, 6)}-${fone.slice(6, 10)}`;
  }
}
