import {Directive, HostListener, Input} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({selector: '[appUppercase]'})
export class UppercaseDirective {
  constructor(private control: NgControl) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    if (this.upperCase) {
      const inputElement = event.target as HTMLInputElement;
      const selectionStart = inputElement.selectionStart;
      const selectionEnd = inputElement.selectionEnd;

      const value = this.control.value?.toUpperCase();
      this.control.control.setValue(value, {emitEvent: false});

      inputElement.value = value;

      inputElement.setSelectionRange(selectionStart, selectionEnd);
    }
  }

  @Input() upperCase: boolean;
}
