<div class="icon-picker" *ngIf="checkType(0)" [ngClass]="{'show':displayIconPicker}" [formGroup]="myFormGroup">
  <input type="text" [formControlName]="myFormControlName" (focus)="showIconPicker()" class="input"
    [ngClass]="options.inputStyleClass">
  <div class="popover">
    <span (click)="closeIconPicker()" class="btn-close"><i class="pi pi-times"></i></span>
    <ng-container *ngTemplateOutlet="seletor"></ng-container>
  </div>
</div>

<div class="icon-picker" *ngIf="checkType(1)" [formGroup]="myFormGroup">
  <div class="block">
    <ng-container *ngTemplateOutlet="seletor"></ng-container>
  </div>
</div>

<ng-template #seletor>
  <div class="icons-groups-selection">
    <span class="icon-group " *ngFor="let iconsGroup of listIconsGroupsToSelection"
      [ngClass]="{'selected': checkSelectedIconsGroup(iconsGroup.groupName)}"
      (click)="setSelectedGroupName(iconsGroup.groupName)">{{iconsGroup.groupName}}</span>
  </div>
  <div class="search">
    <input type="text" class="input" (keyup)="onKeyup($event)" [ngClass]="options.inputStyleClass"
      placeholder="Buscar...">
  </div>
  <div class="icon-list">
    <div class="icon-button" *ngFor="let icon of selectedIconsGroupFiltered" (click)="setSelectedIcon(icon)"
      [ngClass]="{'selected':checkSelectedIcon(icon)}" [pTooltip]="icon" tooltipPosition="bottom">
      <i [class]="icon"></i>
    </div>
  </div>
</ng-template>
