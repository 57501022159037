declare interface Array<T> {
  orderBy(keySelector: (key: T) => any): Array<T>;
  orderByDescending(keySelector: (key: T) => any): Array<T>;
  sum(keySelector: (key: T) => any): number;
  first(): T;
  last(): T;
}
Array.prototype.orderBy = function <T>(keySelector: (key: T) => any) {
  return this.sort((a, b) =>
    keySelector(a) > keySelector(b)
      ? 1
      : keySelector(b) > keySelector(a)
      ? -1
      : 0
  );
};
Array.prototype.orderByDescending = function <T>(keySelector: (key: T) => any) {
  return this.sort((a, b) =>
    keySelector(b) > keySelector(a)
      ? 1
      : keySelector(a) > keySelector(b)
      ? -1
      : 0
  );
};
Array.prototype.sum = function <T>(keySelector: (key: T) => any) {
  return this.map(keySelector).reduce((a, b) => a + b);
};
Array.prototype.first = function () {
  if (this && this.length > 0) {
    return this[0];
  }
  return null;
};
Array.prototype.last = function () {
  if (this && this.length > 0) {
    const length = this.length;
    return this[length - 1];
  }
  return null;
};
