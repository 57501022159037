<h1 class="p-d-flex p-flex-column-reverse p-flex-md-row">
  <div class="p-mr-auto">
    {{this.title}}
    <ng-container *ngIf="showFavoriteButton">
      <i class="pi pi-star cursor-pointer fav" (click)="remover()" *ngIf="checked"></i>
      <i class="pi pi-star-o cursor-pointer fav" (click)="open()" *ngIf="!checked"></i>
    </ng-container>
  </div>
  <div class="p-mr-2">
    <ng-content></ng-content>
  </div>
</h1>

<p-dialog header="Adicionar aos favoritos" position="top" [(visible)]="displayModal" [modal]="true" [baseZIndex]="10000"
  [draggable]="false" [resizable]="false" [formGroup]="form">
  <div class="p-fluid">
    <div class="p-field">
      <app-custom-label texto="Descrição"></app-custom-label>
      <input type="text" pInputText formControlName="descricao">
    </div>
  </div>
  <div class="p-field">
    <div class="p-formgroup-inline">
      <div class="p-field-checkbox">
        <p-radioButton inputId="barraFavoritos" value="1" formControlName="tipo"></p-radioButton>
        <app-custom-label texto="Barra Favoritos" class="cursor-pointer" for="barraFavoritos"></app-custom-label>
      </div>
      <div class="p-field-checkbox">
        <p-radioButton inputId="menuDashboard" value="0" formControlName="tipo"></p-radioButton>
        <app-custom-label texto="Menu Dashboard" class="cursor-pointer" for="menuDashboard"></app-custom-label>
      </div>
    </div>
  </div>
  <ng-template pTemplate="footer">
    <p-button (click)="displayModal=false" label="Fechar" styleClass="p-button-text btn-two"></p-button>
    <p-button (click)="adicionar()" label="Ok" styleClass="p-button-text btn-one"></p-button>
  </ng-template>
</p-dialog>
