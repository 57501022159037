import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[appAutoFocus]',
})
export class AutoFocusDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    if (this.autofocus) {
      this.elementRef.nativeElement.focus();
    }
  }

  @Input() autofocus: boolean;
}
