export enum FormaPagamento {
  BOLETO,
  CREDITO,
  BOLETO_CREDITO,
}

export const FormaPagamentoLabels = new Map<number, string>([
  [FormaPagamento.BOLETO, 'Boleto'],
  [FormaPagamento.CREDITO, 'Crédito'],
  [FormaPagamento.BOLETO_CREDITO, 'Boleto e Crédito'],
]);
