import { Component, Input, AfterViewInit, ElementRef } from '@angular/core';
import { ErrorMessageFacade } from '@app/abstraction/domain/facades/shared/error-message.facade';
import { AcaoMassa } from './interfaces/acao-massa.interface';

@Component({
  selector: 'app-menu-acoes-massa',
  templateUrl: './menu-acoes-massa.component.html',
  styleUrls: ['./menu-acoes-massa.component.scss'],
})
export class MenuAcoesMassaComponent implements AfterViewInit {
  @Input() mostrarMenu: boolean;
  @Input() acoesMassa: AcaoMassa[];
  constructor(
    private el: ElementRef,
    private errorMessageFacade: ErrorMessageFacade
  ) {}
  private alinharComponenteCentro() {
    const nativeElement = this.el.nativeElement as HTMLElement;
    nativeElement.style.display = 'flex';
    nativeElement.style.justifyContent = 'center';
  }
  async executarAcaoEmMassa(acaoEmMassa: AcaoMassa) {
    acaoEmMassa.carregando = true;

    Promise.resolve(acaoEmMassa.comando())
      .catch((e) => {
        acaoEmMassa.carregando = false;
        this.errorMessageFacade.mostrarMensagens(e);
      })
      .finally(() => {
        acaoEmMassa.carregando = false;
      });
  }
  ngAfterViewInit() {
    this.alinharComponenteCentro();
  }
}
