import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@app/shared/constants/api.constant';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.startsWith('http')) {
      return next.handle(req);
    } else {
      const token = localStorage.getItem('access_token');
      const headers = new HttpHeaders({
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `bearer ${token}`,
      });
      const apiReq = req.clone({
        url: `${API.MANAGER_BASE_URL}/${req.url}`,
        body: JSON.stringify(req.body),
        headers: headers,
      });
      return next.handle(apiReq);
    }
  }
}
