import {Injectable} from '@angular/core';
import {ServicoService} from '@app/abstraction/data/services/cadastros/servico.service';
import {ServicoStore} from '@app/abstraction/data/stores/cadastros/servico.store';

import {ServicoFiltro} from '../../interfaces/servico/servico-filtro.interface';

@Injectable({providedIn: 'root'})
export class ServicoFacade {
  get servicos() {
    return this.store.servicos;
  }
  get servicos$() {
    return this.store.servicos$;
  }

  constructor(private store: ServicoStore, private service: ServicoService) {}

  async buscarServicos(filtro?: ServicoFiltro) {
    const servicos = await this.service.buscarTodos(filtro);
    if (servicos) {
      this.store.adicionarRange(servicos);
    }
  }
  limparListaServicos() {
    this.store.servicos = null;
  }
}
