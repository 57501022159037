<div *ngIf="control">
  <div *ngIf="!group">
    <p-multiSelect
      #contentInput
      selectedItemsLabel="{0} itens selecionadas"
      appendTo="body"
      styleClass="p-inputtext-md inputfield w-full"
      [options]="options"
      [placeholder]="placeholder"
      [optionLabel]="optionLabel"
      [optionValue]="optionValue"
      [formControl]="control"
      [emptyMessage]="emptyMessage"
      [emptyFilterMessage]="emptyFilterMessage"
      [readonly]="readonly"
      [showClear]="showClear"
      [filter]="showFilter"
      [ngClass]="{
        readonly: readonly,
        'ng-invalid ng-dirty': hasErrors
      }"
    >
    </p-multiSelect>
  </div>
  <div *ngIf="group">
    <p-multiSelect
      #contentInput
      selectedItemsLabel="{0} itens selecionadas"
      appendTo="body"
      styleClass="p-inputtext-md inputfield w-full"
      [options]="options"
      [placeholder]="placeholder"
      [formControl]="control"
      [emptyMessage]="emptyMessage"
      [emptyFilterMessage]="emptyFilterMessage"
      [readonly]="readonly"
      [showClear]="showClear"
      [filter]="showFilter"
      [group]="group"
      [scrollHeight]="scrollHeight + 'px'"
      [ngClass]="{
        readonly: readonly,
        'ng-invalid ng-dirty': hasErrors
      }"
    >
    </p-multiSelect>
  </div>
  <ng-container *ngFor="let error of errors">
    <small class="p-error">{{ getErrorMessage(control, error) }}<br /></small>
  </ng-container>
</div>
