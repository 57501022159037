export class PaisConst {
  constructor(_paisDescricao: string, _paisIbge: string, _paisSigla?: string) {
    this.paisDescricao = _paisDescricao;
    this.paisIbge = _paisIbge;
    this.paisSigla = _paisSigla;
  }
  paisSigla: string;
  paisDescricao: string;
  paisIbge: string;
  _descricaoIbge: string;
  get descricaoIbge() {
    return this.paisDescricao ? '' : this.paisDescricao + ' - ' + this.paisIbge;
  }
}

export const Paises = [
  new PaisConst('AFEGANISTAO', '0132'),
  new PaisConst('AFRICA DO SUL', '7560'),
  new PaisConst('ALBANIA, REPUBLICA DA', '0175'),
  new PaisConst('ALEMANHA', '0230'),
  new PaisConst('ANDORRA', '0370'),
  new PaisConst('ANGOLA', '0400'),
  new PaisConst('ANGUILLA', '0418'),
  new PaisConst('ANTIGUA E BARBUDA', '0434'),
  new PaisConst('ANTILHAS HOLANDESAS', '0477'),
  new PaisConst('ARABIA SAUDITA', '0531'),
  new PaisConst('ARGELIA', '0590'),
  new PaisConst('ARGENTINA', '0639'),
  new PaisConst('ARMENIA, REPUBLICA DA', '0647'),
  new PaisConst('ARUBA', '0655'),
  new PaisConst('AUSTRALIA', '0698'),
  new PaisConst('AUSTRIA', '0728'),
  new PaisConst('AZERBAIJAO, REPUBLICA DO', '0736'),
  new PaisConst('BAHAMAS, ILHAS', '0779'),
  new PaisConst('BAHREIN, ILHAS', '0809'),
  new PaisConst('BANGLADESH', '0817'),
  new PaisConst('BARBADOS', '0833'),
  new PaisConst('BELARUS, REPUBLICA DA', '0850'),
  new PaisConst('BELGICA', '0876'),
  new PaisConst('BELIZE', '0884'),
  new PaisConst('BENIN', '2291'),
  new PaisConst('BERMUDAS', '0906'),
  new PaisConst('BOLIVIA', '0973'),
  new PaisConst('BOSNIA-HERZEGOVINA (REPUBLICA DA)', '0981'),
  new PaisConst('BOTSUANA', '1015'),
  new PaisConst('BRASIL', '1058'),
  new PaisConst('BRUNEI', '1082'),
  new PaisConst('BULGARIA, REPUBLICA DA', '1112'),
  new PaisConst('BURKINA FASO', '0310'),
  new PaisConst('BURUNDI', '1155'),
  new PaisConst('BUTAO', '1198'),
  new PaisConst('CABO VERDE, REPUBLICA DE', '1279'),
  new PaisConst('CAMAROES', '1457'),
  new PaisConst('CAMBOJA', '1414'),
  new PaisConst('CANADA', '1490'),
  new PaisConst('CANARIAS, ILHAS', '1511'),
  new PaisConst('CATAR', '1546'),
  new PaisConst('CAYMAN, ILHAS', '1376'),
  new PaisConst('CAZAQUISTAO, REPUBLICA DO', '1538'),
  new PaisConst('CHADE', '7889'),
  new PaisConst('CHILE', '1589'),
  new PaisConst('CHINA, REPUBLICA POPULAR', '1600'),
  new PaisConst('CHIPRE', '1635'),
  new PaisConst('CHRISTMAS,ILHA (NAVIDAD)', '5118'),
  new PaisConst('CINGAPURA', '7412'),
  new PaisConst('COCOS(KEELING),ILHAS', '1651'),
  new PaisConst('COLOMBIA', '1694'),
  new PaisConst('COMORES, ILHAS', '1732'),
  new PaisConst('CONGO', '1775'),
  new PaisConst('CONGO, REPUBLICA DEMOCRATICA DO', '8885'),
  new PaisConst('COOK, ILHAS', '1830'),
  new PaisConst('COREIA, REP.POP.DEMOCRATICA', '1872'),
  new PaisConst('COREIA, REPUBLICA DA', '1902'),
  new PaisConst('COSTA DO MARFIM', '1937'),
  new PaisConst('COSTA RICA', '1961'),
  new PaisConst('COVEITE', '1988'),
  new PaisConst('CROACIA (REPUBLICA DA)', '1953'),
  new PaisConst('CUBA', '1996'),
  new PaisConst('DINAMARCA', '2321'),
  new PaisConst('DJIBUTI', '7838'),
  new PaisConst('DOMINICA,ILHA', '2356'),
  new PaisConst('EGITO', '2402'),
  new PaisConst('EL SALVADOR', '6874'),
  new PaisConst('EMIRADOS ARABES UNIDOS', '2445'),
  new PaisConst('EQUADOR', '2399'),
  new PaisConst('ERITREIA', '2437'),
  new PaisConst('ESLOVACA, REPUBLICA', '2470'),
  new PaisConst('ESLOVENIA, REPUBLICA DA', '2461'),
  new PaisConst('ESPANHA', '2453'),
  new PaisConst('ESTADOS UNIDOS', '2496'),
  new PaisConst('ESTONIA, REPUBLICA DA', '2518'),
  new PaisConst('ETIOPIA', '2534'),
  new PaisConst('FALKLAND (ILHAS MALVINAS)', '2550'),
  new PaisConst('FEROE, ILHAS', '2593'),
  new PaisConst('FIJI', '8702'),
  new PaisConst('FILIPINAS', '2674'),
  new PaisConst('FINLANDIA', '2712'),
  new PaisConst('FORMOSA (TAIWAN)', '1619'),
  new PaisConst('FRANCA', '2755'),
  new PaisConst('GABAO', '2810'),
  new PaisConst('GAMBIA', '2852'),
  new PaisConst('GANA', '2895'),
  new PaisConst('GEORGIA, REPUBLICA DA', '2917'),
  new PaisConst('GIBRALTAR', '2933'),
  new PaisConst('GRANADA', '2976'),
  new PaisConst('GRECIA', '3018'),
  new PaisConst('GROENLANDIA', '3050'),
  new PaisConst('GUADALUPE', '3093'),
  new PaisConst('GUAM', '3131'),
  new PaisConst('GUATEMALA', '3174'),
  new PaisConst('GUERNSEY, ILHA DO CANAL (INCLUI ALDERNEY E SARK)', '1504'),
  new PaisConst('GUIANA', '3379'),
  new PaisConst('GUIANA FRANCESA', '3255'),
  new PaisConst('GUINE', '3298'),
  new PaisConst('GUINE-BISSAU', '3344'),
  new PaisConst('GUINE-EQUATORIAL', '3310'),
  new PaisConst('HAITI', '3417'),
  new PaisConst('HONDURAS', '3450'),
  new PaisConst('HONG KONG', '3514'),
  new PaisConst('HUNGRIA, REPUBLICA DA', '3557'),
  new PaisConst('IEMEN', '3573'),
  new PaisConst('INDIA', '3611'),
  new PaisConst('INDONESIA', '3654'),
  new PaisConst('IRA, REPUBLICA ISLAMICA DO', '3727'),
  new PaisConst('IRAQUE', '3697'),
  new PaisConst('IRLANDA', '3751'),
  new PaisConst('ISLANDIA', '3794'),
  new PaisConst('ISRAEL', '3832'),
  new PaisConst('ITALIA', '3867'),
  new PaisConst('JAMAICA', '3913'),
  new PaisConst('JAPAO', '3999'),
  new PaisConst('JERSEY, ILHA DO CANAL', '1508'),
  new PaisConst('JOHNSTON, ILHAS', '3964'),
  new PaisConst('JORDANIA', '4030'),
  new PaisConst('KIRIBATI', '4111'),
  new PaisConst('LAOS, REP.POP.DEMOCR.DO', '4200'),
  new PaisConst('LEBUAN,ILHAS', '4235'),
  new PaisConst('LESOTO', '4260'),
  new PaisConst('LETONIA, REPUBLICA DA', '4278'),
  new PaisConst('LIBANO', '4316'),
  new PaisConst('LIBERIA', '4340'),
  new PaisConst('LIBIA', '4383'),
  new PaisConst('LIECHTENSTEIN', '4405'),
  new PaisConst('LITUANIA, REPUBLICA DA', '4421'),
  new PaisConst('LUXEMBURGO', '4456'),
  new PaisConst('MACAU', '4472'),
  new PaisConst('MACEDONIA, ANT.REP.IUGOSLAVA', '4499'),
  new PaisConst('MADAGASCAR', '4502'),
  new PaisConst('MADEIRA, ILHA DA', '4525'),
  new PaisConst('MALASIA', '4553'),
  new PaisConst('MALAVI', '4588'),
  new PaisConst('MALDIVAS', '4618'),
  new PaisConst('MALI', '4642'),
  new PaisConst('MALTA', '4677'),
  new PaisConst('MAN, ILHA DE', '3595'),
  new PaisConst('MARIANAS DO NORTE', '4723'),
  new PaisConst('MARROCOS', '4740'),
  new PaisConst('MARSHALL,ILHAS', '4766'),
  new PaisConst('MARTINICA', '4774'),
  new PaisConst('MAURICIO', '4855'),
  new PaisConst('MAURITANIA', '4880'),
  new PaisConst('MAYOTTE (ILHAS FRANCESAS)', '4885'),
  new PaisConst('MEXICO', '4936'),
  new PaisConst('MIANMAR (BIRMANIA)', '0930'),
  new PaisConst('MICRONESIA', '4995'),
  new PaisConst('MIDWAY, ILHAS', '4901'),
  new PaisConst('MOCAMBIQUE', '5053'),
  new PaisConst('MOLDAVIA, REPUBLICA DA', '4944'),
  new PaisConst('MONACO', '4952'),
  new PaisConst('MONGOLIA', '4979'),
  new PaisConst('MONTENEGRO', '4985'),
  new PaisConst('MONTSERRAT,ILHAS', '5010'),
  new PaisConst('NAMIBIA', '5070'),
  new PaisConst('NAURU', '5088'),
  new PaisConst('NEPAL', '5177'),
  new PaisConst('NICARAGUA', '5215'),
  new PaisConst('NIGER', '5258'),
  new PaisConst('NIGERIA', '5282'),
  new PaisConst('NIUE,ILHA', '5312'),
  new PaisConst('NORFOLK,ILHA', '5355'),
  new PaisConst('NORUEGA', '5380'),
  new PaisConst('NOVA CALEDONIA', '5428'),
  new PaisConst('NOVA ZELANDIA', '5487'),
  new PaisConst('OMA', '5568'),
  new PaisConst('PACIFICO,ILHAS DO (POSSESSAO DOS EUA)', '5665'),
  new PaisConst('PAISES BAIXOS (HOLANDA)', '5738'),
  new PaisConst('PALAU', '5754'),
  new PaisConst('PALESTINA', '5780'),
  new PaisConst('PANAMA', '5800'),
  new PaisConst('PAPUA NOVA GUINE', '5452'),
  new PaisConst('PAQUISTAO', '5762'),
  new PaisConst('PARAGUAI', '5860'),
  new PaisConst('PERU', '5894'),
  new PaisConst('PITCAIRN,ILHA', '5932'),
  new PaisConst('POLINESIA FRANCESA', '5991'),
  new PaisConst('POLONIA, REPUBLICA DA', '6033'),
  new PaisConst('PORTO RICO', '6114'),
  new PaisConst('PORTUGAL', '6076'),
  new PaisConst('QUENIA', '6238'),
  new PaisConst('QUIRGUIZ, REPUBLICA', '6254'),
  new PaisConst('REINO UNIDO', '6289'),
  new PaisConst('REPUBLICA CENTRO-AFRICANA', '6408'),
  new PaisConst('REPUBLICA DOMINICANA', '6475'),
  new PaisConst('REUNIAO, ILHA', '6602'),
  new PaisConst('ROMENIA', '6700'),
  new PaisConst('RUANDA', '6750'),
  new PaisConst('RUSSIA, FEDERACAO DA', '6769'),
  new PaisConst('SAARA OCIDENTAL', '6858'),
  new PaisConst('SAINT KITTS E NEVIS', '6781'),
  new PaisConst('SALOMAO, ILHAS', '6777'),
  new PaisConst('SAMOA', '6904'),
  new PaisConst('SAMOA AMERICANA', '6912'),
  new PaisConst('SAN MARINO', '6971'),
  new PaisConst('SANTA HELENA', '7102'),
  new PaisConst('SANTA LUCIA', '7153'),
  new PaisConst('SAO CRISTOVAO E NEVES,ILHAS', '6955'),
  new PaisConst('SAO PEDRO E MIQUELON', '7005'),
  new PaisConst('SAO TOME E PRINCIPE, ILHAS', '7200'),
  new PaisConst('SAO VICENTE E GRANADINAS', '7056'),
  new PaisConst('SENEGAL', '7285'),
  new PaisConst('SERRA LEOA', '7358'),
  new PaisConst('SERVIA', '7370'),
  new PaisConst('SEYCHELLES', '7315'),
  new PaisConst('SIRIA, REPUBLICA ARABE DA', '7447'),
  new PaisConst('SOMALIA', '7480'),
  new PaisConst('SRI LANKA', '7501'),
  new PaisConst('SUAZILANDIA', '7544'),
  new PaisConst('SUDAO', '7595'),
  new PaisConst('SUDAO DO SUL', '7600'),
  new PaisConst('SUECIA', '7641'),
  new PaisConst('SUICA', '7676'),
  new PaisConst('SURINAME', '7706'),
  new PaisConst('TADJIQUISTAO, REPUBLICA DO', '7722'),
  new PaisConst('TAILANDIA', '7765'),
  new PaisConst('TANZANIA, REP.UNIDA DA', '7803'),
  new PaisConst('TCHECA, REPUBLICA', '7919'),
  new PaisConst('TERRITORIO BRIT.OC.INDICO', '7820'),
  new PaisConst('TIMOR LESTE', '7951'),
  new PaisConst('TOGO', '8001'),
  new PaisConst('TONGA', '8109'),
  new PaisConst('TOQUELAU,ILHAS', '8052'),
  new PaisConst('TRINIDAD E TOBAGO', '8150'),
  new PaisConst('TUNISIA', '8206'),
  new PaisConst('TURCAS E CAICOS,ILHAS', '8230'),
  new PaisConst('TURCOMENISTAO, REPUBLICA DO', '8249'),
  new PaisConst('TURQUIA', '8273'),
  new PaisConst('TUVALU', '8281'),
  new PaisConst('UCRANIA', '8311'),
  new PaisConst('UGANDA', '8338'),
  new PaisConst('URUGUAI', '8451'),
  new PaisConst('UZBEQUISTAO, REPUBLICA DO', '8478'),
  new PaisConst('VANUATU', '5517'),
  new PaisConst('VATICANO, EST.DA CIDADE DO', '8486'),
  new PaisConst('VENEZUELA', '8508'),
  new PaisConst('VIETNA', '8583'),
  new PaisConst('VIRGENS,ILHAS (BRITANICAS)', '8630'),
  new PaisConst('VIRGENS,ILHAS (E.U.A.)', '8664'),
  new PaisConst('WAKE, ILHA', '8737'),
  new PaisConst('ZAMBIA', '8907'),
  new PaisConst('ZIMBABUE', '6653'),
  new PaisConst('ZONA DO CANAL DO PANAMA', '8958')
];
