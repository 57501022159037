import '@app/shared/extensions/string.extension';

import {Component, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message.facade';
import {BaseComponent} from '@app/shared/components/base/base.component';
import {ToastrService} from 'ngx-toastr';
import {MenuItem} from 'primeng/api';

@Component({
  selector: 'app-titulo',
  templateUrl: './titulo.component.html',
  styleUrls: ['./titulo.component.scss'],
})
export class TituloComponent extends BaseComponent {
  @Input() titulo: string;
  breadcrumbs: MenuItem[] = [];
  constructor(
      private activatedRoute: ActivatedRoute, toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade) {
    super(toastrService, errorMessagesFacade);
    this.montarBreadcrumbs();
  }
  private montarBreadcrumbs() {
    const data = this.activatedRoute.snapshot.data;

    let module = data['module'];
    let permissao = data['permissao'];
    let screen = data['screen'];
    let rota = data['rota'];

    if (module) {
      this.breadcrumbs.push({
        label: module,
      });
    }
    if (screen) {
      this.breadcrumbs.push({
        label: screen,
        routerLink: `/${module.toLowerCase().removerAcentos()}/${rota}`,
      });
    }
    if (permissao) {
      this.breadcrumbs.push({
        label: permissao,
      });
    }
  }
}
