export enum TipoUsuario {
  Desenvolvedor = 1,
  Suporte,
  Revenda,
}

export const TipoUsuarioLabels = new Map<number, string>([
  [TipoUsuario.Desenvolvedor, 'Desenvolvedor'],
  [TipoUsuario.Suporte, 'Suporte'],
  [TipoUsuario.Revenda, 'Revenda'],
]);
