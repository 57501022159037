import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {OAuthFacade} from '@app/abstraction/domain/facades/shared/auth/o-auth.facade';
import {MenuItem} from 'primeng/api';

import {LayoutService} from './service/app.layout.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent implements OnInit {
  items: MenuItem[];

  @ViewChild('menubutton') menuButton!: ElementRef;
  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;
  @ViewChild('topbarmenu') menu!: ElementRef;

  constructor(
      public layoutService: LayoutService,
      private oauthFacade: OAuthFacade,
  ) {}
  private criarMenuDrodown() {
    this.items = [
      {
        label: 'Sair',
        icon: 'pi pi-fw pi-power-off',
        command: () => this.sair(),
      },
    ];
  }
  ngOnInit(): void {
    this.criarMenuDrodown();
  }
  sair() {
    this.oauthFacade.logout();
  }
}
