import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableColumn } from '@app/shared/interfaces/components/table-column.interface';

@Component({
  selector: 'app-multi-select-colunas',
  templateUrl: './multi-select-colunas.component.html',
})
export class MultiSelectColunasComponent {
  @Input() colunasOcultas: TableColumn[];
  @Output() aoAlterar = new EventEmitter<any>();
  get mostrarMultiSelect() {
    return this.colunasOcultas?.length > 0;
  }

  emitirChange(evento: any) {
    this.aoAlterar.emit(evento);
  }
}
