import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { IconPickerOptions } from './interfaces/icon-picker-options.interface';
import { IconGroup } from './interfaces/icon-group.interface';
import { IconPickerTypes } from './enums/icon-picker-types.enum';

@Component({
  selector: 'app-icon-picker',
  templateUrl: './icon-picker.component.html',
  styleUrls: ['./icon-picker.component.scss'],
})
export class IconPickerComponent implements OnInit {
  @Input() myFormControlName: string;
  @Input() myFormGroup: UntypedFormGroup;
  @Input() options: IconPickerOptions;

  displayIconPicker: boolean;
  filter: string;
  selectedGroupName: string;
  selectedIconsGroup: IconGroup;

  get selectedIcon() {
    return this.myFormGroup.get(this.myFormControlName).value;
  }
  get listIconsGroupsToSelection() {
    return this.options.iconsGroups;
  }
  get selectedIconsGroupFiltered() {
    return !this.filter
      ? this.selectedIconsGroup.icons
      : this.selectedIconsGroup.icons.filter((e: string) => {
          return e.toLowerCase().indexOf(this.filter.toLowerCase()) > -1;
        });
  }

  private setSelectedIconsGroup() {
    this.selectedIconsGroup = this.options.iconsGroups.find(
      (e) => e.groupName === this.selectedGroupName
    );
  }
  private setFirstSelectedIconsGroup() {
    if (this.options.iconsGroups) {
      this.selectedGroupName = this.options.iconsGroups.first().groupName;
    }
  }
  ngOnInit() {
    this.setFirstSelectedIconsGroup();
    this.setSelectedIconsGroup();
  }
  setSelectedIcon(icon: string) {
    this.myFormGroup.get(this.myFormControlName).setValue(icon);
    this.closeIconPicker();
  }
  setSelectedGroupName(groupName: string) {
    this.selectedGroupName = groupName;
    this.setSelectedIconsGroup();
  }
  showIconPicker() {
    this.displayIconPicker = true;
  }
  closeIconPicker() {
    this.displayIconPicker = false;
  }
  checkSelectedIcon(icon: string) {
    return this.selectedIcon === icon;
  }
  checkSelectedIconsGroup(groupName: string) {
    return this.selectedGroupName === groupName;
  }
  checkType(type: IconPickerTypes) {
    return this.options.type === type;
  }
  onKeyup(event: any) {
    this.filter = event.target.value;
  }
}
