import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Endereco } from '@app/abstraction/domain/entities/shared/endereco.entity';

@Component({
  selector: 'app-cartao-endereco',
  templateUrl: './cartao-endereco.component.html',
  styleUrls: ['./cartao-endereco.component.scss'],
})
export class CartaoEnderecoComponent {
  @Input() endereco: Endereco;
  @Output() aoCliquarEmEditar = new EventEmitter();

  editarClicado() {
    this.aoCliquarEmEditar.emit();
  }
}
