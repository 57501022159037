import {
  Directive,
  Host,
  HostListener,
  Input,
  OnInit,
  Optional,
  SimpleChange,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { NgxMaskDirective } from 'ngx-mask';

@Directive({
  selector: '[appFoneMask]',
})
export class FoneMaskDirective implements OnInit {
  @Input() foneMask: boolean;
  @Input() onlyCellPhoneMask: boolean;
  @Input() onlyPhoneMask: boolean;
  mascaraCelular = '(00) 00000-0000';
  mascaraFixo = '(00) 0000-0000';
  mascara0800 = '0000 000 0000';
  currentMask = '';

  constructor(
    @Host() @Optional() private mask: NgxMaskDirective,
    private control: NgControl
  ) {}

  ngOnInit(): void {
    this.onInputChange();
  }
  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: any) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event: any) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event?: any, backspace?: any) {
    if (!this.foneMask) return;
    let telefone = event ? event?.somenteNumeros() : this.control.value;
    if (telefone) {
      if (this.onlyCellPhoneMask) {
        this.currentMask = this.mascaraCelular;
        this.mask.ngOnChanges({
          maskExpression: new SimpleChange(null, this.mascaraCelular, false),
        });
      } else if (this.onlyPhoneMask) {
        this.currentMask = this.mascaraFixo;
        this.mask.ngOnChanges({
          maskExpression: new SimpleChange(null, this.mascaraFixo, false),
        });
      } else {
        if (backspace && telefone.length <= 6) {
          telefone = telefone.substring(0, telefone.length - 1);
        }
        if (telefone.startsWith('0800')) {
          this.currentMask = this.mascara0800;
          this.mask.ngOnChanges({
            maskExpression: new SimpleChange(null, this.mascara0800, false),
          });
        } else {
          if (telefone.length <= 10 && telefone.charAt(2) !== '9') {
            if (this.currentMask !== this.mascaraFixo) {
              this.currentMask = this.mascaraFixo;
              this.mask.ngOnChanges({
                maskExpression: new SimpleChange(null, this.mascaraFixo, false),
              });
            }
          } else {
            this.currentMask = this.mascaraCelular;
            this.mask.ngOnChanges({
              maskExpression: new SimpleChange(
                null,
                this.mascaraCelular,
                false
              ),
            });
          }
        }
      }
    }
    this.control.valueAccessor.writeValue(telefone);
  }
}
