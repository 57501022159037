import '@app/shared/extensions/array.extension';

import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message.facade';
import {CardHeaderOpcoes} from '@app/shared/components/dumb/card/interfaces/card-header-opcoes.interface';
import {EstadoConst, Estados} from '@app/shared/constants/estados.constant';
import {AmbienteLabels} from '@app/shared/enums/ambiente.enum';
import {AtividadeTipoLabels} from '@app/shared/enums/atividade-tipo.enum';
import {CertificadoTipoLabels} from '@app/shared/enums/certificado-tipo.enum';
import {ConsultaDataPeriodoLabels} from '@app/shared/enums/consulta-data-tipo.enum';
import {ContextoLabels} from '@app/shared/enums/contexto.enum';
import {DetalheVersaoLabels} from '@app/shared/enums/detalhe-versao.enum';
import {FormaPagamentoLabels} from '@app/shared/enums/forma-pagamento.enum';
import {IeIndicadorLabels} from '@app/shared/enums/ie-indicador.enum';
import {PessoaTipoLabels} from '@app/shared/enums/pessoa-tipo.enum';
import {RegimeTributarioLabels} from '@app/shared/enums/regimes-tributarios.enum';
import {SituacaoReleaseNoteLabels} from '@app/shared/enums/situacao-release-note.enum';
import {TipoIntervaloLabels} from '@app/shared/enums/tipo-intervalo.enum';
import {TipoReleaseNoteLabels} from '@app/shared/enums/tipo-release-note.enum';
import {TipoUsuarioLabels} from '@app/shared/enums/tipo-usuario.enum';
import {DropdownItem} from '@app/shared/interfaces/components/dropdown-item.interface';
import {ToastrService} from 'ngx-toastr';
import {MenuItem} from 'primeng/api';

import {ExtraButton} from '../dumb/card/interfaces/extra-button.interface';

@Component({
  template: '',
})
export class BaseComponent {
  ambientes: DropdownItem[] = [];
  detalhesVersao: DropdownItem[] = [];
  consultaDataPeriodos: DropdownItem[] = [];
  tiposUsuario: DropdownItem[] = [];
  contextos: DropdownItem[] = [];
  situacoesReleaseNote: DropdownItem[] = [];
  tiposReleaseNote: DropdownItem[] = [];
  regimesTributarios: DropdownItem[] = [];
  certificadoTipos: DropdownItem[] = [];
  atividadeTipos: DropdownItem[] = [];
  ieIndicadores: DropdownItem[] = [];
  pessoaTipos: DropdownItem[] = [];
  estadosIbge: DropdownItem[] = [];
  gruposEconomicos: DropdownItem[] = [];
  tipoIntervalos: DropdownItem[] = [];
  formasPagamento: DropdownItem[] = [];
  itemsBreadcrumb: MenuItem[];
  botoes: ExtraButton[] = [];
  opcoes: CardHeaderOpcoes;
  saveLoading: boolean;
  dataFormatoPt = 'dd/MM/yyyy';
  mascaraDocumento = '000.000.000-00||00.000.000/0000-00';
  optionsInteiroSemAgrupamento = {
    align: 'right',
    allowNegative: false,
    allowZero: true,
    precision: 0,
    prefix: '',
    suffix: '',
    thousands: '',
    nullable: true,
  };
  booleano: DropdownItem[] = [
    <DropdownItem>{
      texto: 'Sim',
      valor: true,
    },
    <DropdownItem>{
      texto: 'Não',
      valor: false,
    },
  ];
  situacoes: DropdownItem[] = [
    <DropdownItem>{
      texto: 'Ativo',
      valor: true,
    },
    <DropdownItem>{
      texto: 'Inativo',
      valor: false,
    },
  ];
  constructor(
      public toastrService: ToastrService,
      public errorMessagesFacade?: ErrorMessageFacade) {}
  private setarCamposInvalidos(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof UntypedFormGroup) {
        this.setarCamposInvalidos(control);
      }
    });
  }
  formControl(name: string, formGroup: UntypedFormGroup) {
    return formGroup.get(name);
  }
  montarDropdownAmbiente() {
    AmbienteLabels.forEach((valor, chave) => {
      this.ambientes.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }

  montarDropdownTipoIntervalos() {
    TipoIntervaloLabels.forEach((valor, chave) => {
      this.tipoIntervalos.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }

  montarDropdownFormasPagamento() {
    FormaPagamentoLabels.forEach((valor, chave) => {
      this.formasPagamento.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }

  montarDropdownDetalheVersao() {
    DetalheVersaoLabels.forEach((valor, chave) => {
      this.detalhesVersao.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownSituacoesReleaseNote() {
    SituacaoReleaseNoteLabels.forEach((valor, chave) => {
      this.situacoesReleaseNote.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownTiposUsuario() {
    TipoUsuarioLabels.forEach((valor, chave) => {
      this.tiposUsuario.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownTipoReleaseNote() {
    TipoReleaseNoteLabels.forEach((valor, chave) => {
      this.tiposReleaseNote.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownContexto() {
    ContextoLabels.forEach((valor, chave) => {
      this.contextos.push(<DropdownItem>{
        texto: `${chave} - ${valor}`,
        valor: chave,
      });
    });
  }
  montarDropdownConsultaDataPeriodos() {
    ConsultaDataPeriodoLabels.forEach((valor, chave) => {
      this.consultaDataPeriodos.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownBooleano() {
    this.booleano = [
      <DropdownItem>{
        texto: 'Sim',
        valor: true,
      },
      <DropdownItem>{
        texto: 'Não',
        valor: false,
      },
    ];
  }
  montarDropdownRegimeTributario() {
    RegimeTributarioLabels.forEach((valor, chave) => {
      this.regimesTributarios.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownCertificadoTipos() {
    CertificadoTipoLabels.forEach((valor, chave) => {
      this.certificadoTipos.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownAtividadeTipo() {
    AtividadeTipoLabels.forEach((valor, chave) => {
      this.atividadeTipos.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownIeIndicador() {
    IeIndicadorLabels.forEach((valor, chave) => {
      this.ieIndicadores.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownPessoaTipo() {
    PessoaTipoLabels.forEach((valor, chave) => {
      this.pessoaTipos.push(<DropdownItem>{
        texto: valor,
        valor: chave,
      });
    });
  }
  montarDropdownEstadosIbge() {
    Estados.sort((a, b) => a.ufDescricao.localeCompare(b.ufDescricao))
        .forEach((estado: EstadoConst) => {
          this.estadosIbge.push(<DropdownItem>{
            texto: estado.ufDescricao,
            valor: estado.ufIbge,
          });
        });
  }
  montarDropdownGrupoEconomico() {
    this.gruposEconomicos = [
      <DropdownItem>{
        texto: 'TODOS',
        valor: 0,
      },
      <DropdownItem>{
        texto: 'MATRIZ',
        valor: 1,
      },
      <DropdownItem>{
        texto: 'FILIAL',
        valor: 2,
      },
    ];
  }
  retornarCEPMask(): string {
    return '00000-000';
  }
}
