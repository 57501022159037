import {Injectable} from '@angular/core';
import {ModuloListagem} from '@app/abstraction/domain/interfaces/modulo/modulo-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ModuloStore {
  private readonly _modulos = new BehaviorSubject<ModuloListagem[]>(null);

  get modulos(): ModuloListagem[] {
    return this._modulos.getValue();
  }
  set modulos(modulos: ModuloListagem[]) {
    this._modulos.next(modulos);
  }
  get modulos$() {
    return this._modulos.asObservable();
  }
  adicionar(modulo: ModuloListagem) {
    if (!this.modulos) {
      this.modulos = [];
    }
    this.modulos.push(modulo);
  }
  adicionarRange(modulos: ModuloListagem[]) {
    if (!this.modulos) {
      this.modulos = [];
    }

    modulos.forEach((modulo) => {
      if (!this.modulos.some((c) => c.id === modulo.id)) {
        this.modulos.push(modulo);
      }
    });
  }
}
