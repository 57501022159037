import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomLabelIconOptions } from './interfaces/custom-label-icon-options.interface';

@Component({
  selector: 'app-custom-label',
  templateUrl: './custom-label.component.html',
  styleUrls: ['./custom-label.component.scss'],
})
export class CustomLabelComponent {
  @Input() cadastroRapido: boolean;
  @Input() info: string;
  @Input() labelTab: boolean;
  @Input() obrigatorio: boolean;
  @Input() texto: string;
  @Input() showIcon: boolean;
  @Input() for: string;
  @Input() iconOptions: CustomLabelIconOptions;
  @Output() aoClicarAdicionar = new EventEmitter();
  @Output() aoClicarImportar = new EventEmitter();

  adicionar() {
    this.aoClicarAdicionar.emit();
  }
  importar() {
    this.aoClicarImportar.emit();
  }
}
