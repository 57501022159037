<router-outlet></router-outlet>
<p-scrollTop></p-scrollTop>
<ngx-ui-loader></ngx-ui-loader>

<ngx-loading-bar
  height="4px"
  [includeSpinner]="false"
  class="loading-bar"
></ngx-loading-bar>

<ngx-loading-bar
  height="4px"
  [includeSpinner]="false"
  ref="router"
  class="loading-bar"
></ngx-loading-bar>

<ngx-loading-bar
  height="4px"
  [includeSpinner]="false"
  ref="http"
  class="loading-bar"
></ngx-loading-bar>
