import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-multiselect-input-ngmodel',
  templateUrl: './multiselect-input-ngmodel.component.html',
  styleUrls: ['./multiselect-input-ngmodel.component.scss'],
})
export class MultiselectInputNgModelComponent {
  @Input() options: any[];
  @Input() readonly: boolean;
  @Input() placeholder: string = 'Selecione';
  @Input() emptyMessage: string = 'Nenhum item encontrado';
  @Input() emptyFilterMessage: string = 'Nenhum item encontrado';
  @Input() optionLabel: string = 'texto';
  @Input() optionValue: string = 'valor';
  @Input() showClear: boolean;
  @Input() showFilter: boolean;
  @Input() filterBy: string;
  @Input() display: string = 'comma';
  @Input() templateCor: boolean;
  @Input() modelOptions: any;
  @Input() model: any;
  @Input() group: boolean;
  @Input() scrollHeight: number;
  @Output() modelChange = new EventEmitter();

  @ViewChild('contentInput')
  set _content(_input: ElementRef<any>) {
    if (_input) {
      this.contentInput = _input;
    }
  }
  contentInput: ElementRef<any>;

  onNgModelChange(event: any) {
    this.model = event;
    this.modelChange.emit(event);
  }
}
