import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { BaseInput } from '../base/base-input';

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss'],
})
export class CheckboxInputComponent extends BaseInput {
  @Input() control: FormControl;
  @Input() controlName: string;
  @Input() readonly: boolean;
  @Input() label: string;
  @Input() value: string;
  @Output() valueChange = new EventEmitter();

  @ViewChild('contentInput')
  set _content(_input: ElementRef<any>) {
    if (_input) {
      this.contentInput = _input;
    }
  }
  contentInput: ElementRef<any>;

  onChange(event: any) {
    this.valueChange.emit(event);
  }
}
