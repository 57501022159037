export enum Ambiente {
  Local = 1,
  Homologacao,
  Producao,
}

export const AmbienteLabels = new Map<number, string>([
  [Ambiente.Local, 'Local'],
  [Ambiente.Homologacao, 'Homologação'],
  [Ambiente.Producao, 'Produção'],
]);
