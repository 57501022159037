export enum PessoaTipo {
  Nenhum,
  Fisica,
  Juridica
}

export const PessoaTipoLabels = new Map<number, string>([
  [PessoaTipo.Fisica, '1-FÍSICA'],
  [PessoaTipo.Juridica, '2-JURÍDICA']
]);
