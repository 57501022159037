import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {OAuthFacade} from '@app/abstraction/domain/facades/shared/auth/o-auth.facade';
import {Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private oauthFacade: OAuthFacade) {}
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return this.oauthFacade.refreshToken().pipe(
        switchMap((token) => {
          const apiReq = request.clone({
            headers: request.headers.set(
                'authorization', 'Bearer ' + token.access_token),
          });
          return next.handle(apiReq);
        }),
        catchError(() => {
          this.oauthFacade.logout();
          return next.handle(request);
        }));
  }
  intercept(request: HttpRequest<any>, next: HttpHandler):
      Observable<HttpEvent<Object>> {
    return next.handle(request).pipe(
        catchError((httpError: HttpErrorResponse) => {
          if (httpError.status === 401) {
            if (httpError.error?.error === 'invalid_token') {
              return this.handle401Error(request, next);
            }
          } else if (httpError.status === 403) {
            this.oauthFacade.logout();
          }

          return throwError(() => httpError);
        }));
  }
}
