import {Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef,} from '@angular/core';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';

import {CardHeaderOpcoes} from './interfaces/card-header-opcoes.interface';
import {ExtraButton} from './interfaces/extra-button.interface';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @ContentChild('buscaAvancada') buscaAvancada: TemplateRef<ElementRef>;
  @ContentChild('dropdownEmpresas') dropdownEmpresas: TemplateRef<ElementRef>;
  @Input() optionsDropdownAuxiliar: any[];
  @Input() mostrarSpinner: boolean;
  @Input() colunasOcultas: TableColumn[];
  @Input() optionLabel: string = 'texto';
  @Input() optionValue: string = 'valor';
  @Input() botoes: ExtraButton[] = [];
  @Input() opcoes: CardHeaderOpcoes;
  @Output() aoFiltrar = new EventEmitter<any>();
  @Output() aoFiltrarDropdownAuxiliar = new EventEmitter<any>();
  @Output() aoSalvar = new EventEmitter();
  @Output() aoClicarBotaoExtra = new EventEmitter();
  @Output() mostrarSpinnerChange = new EventEmitter<boolean>();
  @Output() aoLimparCampos = new EventEmitter();
  @Output() aoClicarBuscar = new EventEmitter();
  @Input() filtroDropdownAuxiliar: any = 0;

  displayBuscaAvancada = false;
  filtro: string;

  get contemBotaoVisivel() {
    return this.botoes?.length > 0 && this.botoes.some((p) => p.mostrarBotao);
  }
  constructor() {}
  emitirSalvar() {
    this.aoSalvar.emit();
  }
  emitirAcaoBotaoExtra(event: any) {
    this.aoClicarBotaoExtra.emit(event);
  }
  limparCampos() {
    this.filtro = null;
    this.aoLimparCampos.emit();
  }
  buscar() {
    this.mostrarBuscarAvancada();
    this.aoClicarBuscar.emit();
  }
  filtrar() {
    this.aoFiltrar.emit(this.filtro);
  }
  filtrarDropdownAuxiliar() {
    this.aoFiltrarDropdownAuxiliar.emit(this.filtroDropdownAuxiliar);
  }
  primeiroBotao(botao: any) {
    return botao === this.botoes?.filter((p) => p.mostrarBotao)[0];
  }
  mostrarBuscarAvancada() {
    this.displayBuscaAvancada = !this.displayBuscaAvancada;
    let div = document.getElementById('busca-avancada');
    if (this.displayBuscaAvancada) {
      div.classList.add('show');
    } else {
      div.classList.remove('show');
    }
  }
}
