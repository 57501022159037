<ng-container *ngIf="control">
  <div class="p-inputgroup">
    <p-password
      inputStyleClass="p-inputtext-md inputfield w-full"
      styleClass="p-inputtext-md inputfield w-full "
      [autocomplete]="autocomplete"
      [formControl]="control"
      [toggleMask]="true"
      [feedback]="false"
      [maxLength]="maxlength"
      [minlength]="minLength"
      [ngClass]="{ readonly: readonly }"
    ></p-password>
  </div>
  <small
    style="float: right"
    *ngIf="maxlength"
    [ngClass]="{
      'p-error': hasErrors
    }"
    >{{ controlLength ?? 0 }}/{{ contador.toString() }}</small
  >
  <ng-container *ngFor="let error of errors">
    <small class="p-error">{{ getErrorMessage(control, error) }}<br /></small>
  </ng-container>
</ng-container>
