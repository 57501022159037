import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ServicoFiltro} from '@app/abstraction/domain/interfaces/servico/servico-filtro.interface';
import {ServicoListagem} from '@app/abstraction/domain/interfaces/servico/servico-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string.helper';
import {lastValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ServicoService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient,
  ) {}

  buscarTodos(filtro?: ServicoFiltro) {
    const response$ = this.client.get<ServicoListagem[]>(
        this.geradorQueryString.gerar(`${API.SERVICO}`, filtro));
    return lastValueFrom(response$);
  }
}
