import {HashLocationStrategy, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {SharedModule} from '@app/shared/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    [
      Location,
      {provide: LocationStrategy, useClass: PathLocationStrategy},
      {provide: LocationStrategy, useClass: HashLocationStrategy},
    ],
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
