import { Injectable } from '@angular/core';
import { UsuarioAuth } from '@app/abstraction/domain/entities/usuario/usuario-auth.entity';
import { Favorito } from '@app/shared/interfaces/components/favorito.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UsuarioAuthStore {
  private readonly _usuarioLogado = new BehaviorSubject<UsuarioAuth>(null);

  get usuarioLogado() {
    return this._usuarioLogado.value;
  }
  get usuarioLogado$() {
    return this._usuarioLogado.asObservable();
  }
  set usuarioLogado(usuario: UsuarioAuth) {
    this._usuarioLogado.next(usuario);
  }

  adicionarFavorito(favorito: Favorito) {
    this.usuarioLogado.favoritos = [...this.usuarioLogado.favoritos, favorito];
  }
  removerFavorito(favorito: Favorito) {
    const index = this.usuarioLogado.favoritos.indexOf(favorito);
    this.usuarioLogado.favoritos.splice(index, 1);
  }
}
