import * as moment from 'moment';

declare module 'moment' {
  interface Moment {
    toUtcOffset(): string;
    toLocalOnlyDate(formato?: string): string;
    toLocalOnlyTime(): string;
    toLocalDateTime(): Date;
  }
}

moment.fn.toUtcOffset = function () {
  const offset = moment().utcOffset() / 60;
  return this.utcOffset(offset).format();
};

moment.fn.toLocalOnlyDate = function (formato: string = 'yyyy-MM-DD') {
  return this.format(formato);
};

moment.fn.toLocalOnlyTime = function () {
  return this.format('HH:mm:ss');
};

moment.fn.toLocalDateTime = function (): Date {
  const offset = moment().utcOffset() / 60;
  const utcMoment = this.clone().utc();
  return utcMoment.add(offset, 'hours').toDate();
};
