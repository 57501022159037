import {Component, ElementRef, EventEmitter, Input, Output, ViewChild,} from '@angular/core';
import {FormGroup, UntypedFormGroup} from '@angular/forms';
import {CepFacade} from '@app/abstraction/domain/facades/shared/cep.facade';
import {DeviceDetectorFacade} from '@app/abstraction/domain/facades/shared/device-detector.facade';
import {EnderecoTipoFacade} from '@app/abstraction/domain/facades/shared/endereco-tipo.facade';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message.facade';
import {IEnderecoForm} from '@app/abstraction/domain/forms/endereco.form';
import {FormBaseComponent} from '@app/shared/components/base/form-base.component';
import {Estados} from '@app/shared/constants/estados.constant';
import {Guid} from 'guid-typescript';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-endereco-modal',
  templateUrl: './endereco-modal.component.html',
})
export class EnderecoModalComponent extends FormBaseComponent {
  @ViewChild('numeroModal')
  set element(numeroModal: ElementRef) {
    if (numeroModal) {
      this.numeroModal = numeroModal;
    }
  }
  @Input() override form: FormGroup<IEnderecoForm>;
  @Input() mostrarModal: boolean;
  @Input() titulo: string;
  @Input() mostrarItemTodosEnderecoTipos: boolean;
  @Output() aoCancelar = new EventEmitter();
  @Output() aoSalvar = new EventEmitter<FormGroup<IEnderecoForm>>();
  enderecoTipoPadraoDropdown: any = {
    enderecoTipoId: Guid.create().toString(),
    descricao: 'TODOS',
  };
  numeroModal: ElementRef;
  get isDesktop() {
    return this.deviceDetectorFacade.isDesktop;
  }
  get enderecoTipos() {
    if (this.mostrarItemTodosEnderecoTipos) {
      return [
        this.enderecoTipoPadraoDropdown,
        ...this.enderecoTipoFacade.enderecoTipos,
      ];
    } else {
      return this.enderecoTipoFacade.enderecoTipos;
    }
  }
  constructor(
      private enderecoTipoFacade: EnderecoTipoFacade,
      private deviceDetectorFacade: DeviceDetectorFacade,
      private cepCoreFacade: CepFacade, toastrService: ToastrService,
      errorMessagesFacade: ErrorMessageFacade) {
    super(toastrService, errorMessagesFacade);
    this.montarDropdownEstadosIbge();
  }

  async consultarCepModal() {
    const cep = this.form.controls.cep.value;
    if (cep.length === 8) {
      const endereco = await this.cepCoreFacade.consultarCep(cep);
      if (endereco.erro) {
        this.errorMessagesFacade.mostrarMensagens('CEP não encontrado');
      } else {
        this.form.controls.logradouro.setValue(endereco.logradouro);
        this.form.controls.bairro.setValue(endereco.bairro);
        this.form.controls.cidade.setValue(endereco.localidade);
        this.form.controls.cidadeIbge.setValue(Number(endereco.ibge));

        const estadoSelecionado =
            Estados.find((el) => el.ufSigla === endereco.uf).ufIbge;

        this.form.controls.ufIbge.setValue(estadoSelecionado);

        this.numeroModal.nativeElement.focus();
      }
    }
  }
  consultarCepComEnter(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.consultarCepModal();
    }
  }
  fecharModal() {
    this.aoCancelar.emit();
  }
  salvar() {
    this.aoSalvar.emit(this.form);
  }
}
