import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cep',
})
export class CepPipe implements PipeTransform {
  transform(cep: string | number) {
    cep = cep?.toString();
    if (cep && cep.length === 8) {
      cep = `${cep.slice(0, 5)}-${cep.slice(5, 8)}`;
      return cep;
    }
    return '';
  }
}
