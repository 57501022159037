export enum RegimeTributario {
  Nenhum,
  SimplesNacional,
  SimplesNacionalExcessoSublimite,
  RegimeNormal
}

export const RegimeTributarioLabels = new Map<number, string>([
  [RegimeTributario.SimplesNacional, '1-SIMPLES NACIONAL'],
  [RegimeTributario.SimplesNacionalExcessoSublimite, '2-SIMPLES NACIONAL EXCESSO SUBLIMITE DE RECEITA BRUTA'],
  [RegimeTributario.RegimeNormal, '3-REGIME NORMAL']
]);
