<div class="card">
  <ng-template #headerFiltro>
    <h5 class="bold card-header-titulo">{{ opcoes?.titulo }}</h5>
    <div class="flex">
      <input
        *ngIf="!opcoes.naoMostrarFiltroPrincipal"
        pInputText
        class="filtro-principal p-inputtext-md inputfield card-header-input"
        [(ngModel)]="filtro"
        [placeholder]="opcoes?.placeholder"
        (input)="filtrar()"
      />

      <p-dropdown
        *ngIf="optionsDropdownAuxiliar"
        appendTo="body"
        [optionLabel]="optionLabel"
        [optionValue]="optionValue"
        class="dropdown-auxiliar"
        styleClass="w-full p-inputtext-md inputfield ml-2"
        [options]="optionsDropdownAuxiliar"
        [(ngModel)]="filtroDropdownAuxiliar"
        (ngModelChange)="filtrarDropdownAuxiliar()"
      ></p-dropdown>

      <ng-container *ngIf="dropdownEmpresas">
        <ng-container *ngTemplateOutlet="dropdownEmpresas"></ng-container>
      </ng-container>

      <p-button
        *ngIf="opcoes?.mostrarBotaoBuscaAvancada"
        class="secondary-button"
        [ngClass]="{ 'mr-auto ml-2': opcoes?.mostrarBotaoBuscaAvancada }"
        icon="pi pi-filter"
        (click)="mostrarBuscarAvancada()"
      ></p-button>

      <p-button
        *ngIf="opcoes?.mostrarBotaoAdicionar"
        [label]="opcoes?.textoBotaoAdicionar"
        routerLink="adicionar"
        [ngStyle]="{ float: 'right' }"
        [ngClass]="{
          'ml-3': opcoes?.mostrarBotaoBuscaAvancada,
          'ml-auto': !opcoes?.mostrarBotaoBuscaAvancada
        }"
        icon="pi pi-plus"
      ></p-button>
    </div>
  </ng-template>

  <ng-template #headerCadastro>
    <p-button
      styleClass="p-button-text icon-button"
      icon="pi pi-arrow-left"
      [routerLink]="opcoes?.linkVoltar"
    >
    </p-button>
    <p-button
      *ngIf="!mostrarSpinner"
      label="Salvar"
      class="primary-button"
      icon="pi pi-save"
      (onClick)="emitirSalvar()"
      [ngClass]="{ 'ml-2': contemBotaoVisivel, 'ml-auto': !contemBotaoVisivel }"
      [ngStyle]="{ float: 'right' }"
    ></p-button>
    <p-button
      *ngIf="mostrarSpinner"
      icon="pi pi-spin pi-spinner"
      class="primary-button"
      [disabled]="true"
      [ngClass]="{ 'ml-2': contemBotaoVisivel, 'ml-auto': !contemBotaoVisivel }"
      [ngStyle]="{ float: 'right' }"
    >
    </p-button>
    <ng-container *ngFor="let botao of botoes">
      <p-button
        *ngIf="botao.mostrarBotao && !botao.loading"
        [label]="botao.textoBotao"
        [icon]="botao.iconeBotao"
        (onClick)="emitirAcaoBotaoExtra(botao.acao)"
        styleClass="ml-1"
        [style]="{
          'background-color': botao.cor,
          'border-color': botao.cor,
          'box-shadow': '0px 1px 4px ' + botao.cor,
          float: 'right'
        }"
      >
      </p-button>
      <p-button
        *ngIf="botao.loading"
        icon="pi pi-spin pi-spinner"
        class="primary-button"
        [disabled]="true"
        [ngClass]="{
          'ml-auto': primeiroBotao(botao),
          'ml-2': !primeiroBotao(botao),
          'background-color': botao.cor
        }"
        [style]="{
          'background-color': botao.cor,
          'border-color': botao.cor,
          'box-shadow': '0px 1px 4px ' + botao.cor
        }"
        [ngStyle]="{ float: 'right' }"
      >
      </p-button>
    </ng-container>
  </ng-template>

  <div class="card-header">
    <div class="header-filtro" *ngIf="opcoes?.tipo === 1">
      <ng-container *ngTemplateOutlet="headerFiltro"></ng-container>
    </div>
    <div class="header-cadastro" *ngIf="opcoes?.tipo === 2">
      <ng-container *ngTemplateOutlet="headerCadastro"></ng-container>
    </div>
  </div>

  <div id="busca-avancada" class="mb-2 top-menu-panel">
    <p-card subheader="Busca avançada" styleClass="card-menu-listagem">
      <ng-container *ngTemplateOutlet="buscaAvancada"></ng-container>
      <div class="col-12 text-right">
        <p-button
          styleClass="m-1"
          label="Limpar Campos"
          class="secondary-button"
          (onClick)="limparCampos()"
        ></p-button>
        <p-button
          styleClass="m-1"
          label="Buscar"
          class="primary-button"
          (onClick)="buscar()"
        ></p-button>
      </div>
    </p-card>
  </div>

  <div class="card-content">
    <ng-content></ng-content>
  </div>
</div>
