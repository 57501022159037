import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ViaCep} from '@app/shared/interfaces/shared/viacep.interface';
import {lastValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CepService {
  constructor(private client: HttpClient) {}
  consultarCep(cep: string) {
    const url = `https://viacep.com.br/ws/${cep}/json`;
    const response$ = this.client.get<ViaCep>(url);
    return lastValueFrom(response$);
  }
}
