import {DecimalPipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'customDecimal'})
export class customDecimalPipe extends DecimalPipe implements PipeTransform {
  override transform(
      value: any, casasDecimais?: string|number, locale?: string): any {
    return super.transform(
        value, `1.${casasDecimais}-${casasDecimais}`,
        locale ? locale : 'pt-BR');
  }
}
