export enum DetalheVersao {
  Latest = 1,
  Dev,
  Refactor,
  Fix,
}

export const DetalheVersaoLabels = new Map<number, string>([
  [DetalheVersao.Latest, 'Latest'],
  [DetalheVersao.Dev, 'Dev'],
  [DetalheVersao.Refactor, 'Refactor'],
  [DetalheVersao.Fix, 'Fix'],
]);
