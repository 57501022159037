import {inject, Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {OAuthFacade} from '@app/abstraction/domain/facades/shared/auth/o-auth.facade';

@Injectable({providedIn: 'root'})
export class AuthGuard {
  constructor(
      private oauthFacade: OAuthFacade,
      private router: Router,
      private activatedRoute: ActivatedRoute,
  ) {}
  async checarUsuarioNaoLogado(url: string): Promise<boolean|UrlTree> {
    if (this.oauthFacade.isLoggedIn) return false;
    this.activatedRoute.snapshot.queryParams = {returnUrl: url};
    return true;
  }
  async checarUsuarioLogado(url: string): Promise<boolean|UrlTree> {
    this.activatedRoute.snapshot.queryParams = {returnUrl: url};
    if (!this.oauthFacade.isLoggedIn) {
      this.router.navigate(['/auth/login']);
      return false;
    }
    return true;
  }
}

export const usuarioNaoLogado: CanActivateFn =
    (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      return inject(AuthGuard).checarUsuarioNaoLogado(state.url);
    };
export const usuarioLogado: CanActivateFn =
    (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      return inject(AuthGuard).checarUsuarioLogado(state.url);
    };
