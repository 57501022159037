import {Injectable} from '@angular/core';
import {VersaoListagem} from '@app/abstraction/domain/interfaces/versao/versao-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class VersaoStore {
  private readonly _versoes = new BehaviorSubject<VersaoListagem[]>(null);

  get versoes(): VersaoListagem[] {
    return this._versoes.getValue();
  }
  set versoes(versoes: VersaoListagem[]) {
    this._versoes.next(versoes);
  }
  get versoes$() {
    return this._versoes.asObservable();
  }
  adicionar(versao: VersaoListagem) {
    if (!this.versoes) {
      this.versoes = [];
    }
    this.versoes.push(versao);
  }
  adicionarRange(versoes: VersaoListagem[]) {
    if (!this.versoes) {
      this.versoes = [];
    }

    versoes.forEach((versao) => {
      if (!this.versoes.some((c) => c.id === versao.id)) {
        this.versoes.push(versao);
      }
    });
  }
}
