import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';

import {BaseInput} from '../base/base-input';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
})
export class PasswordInputComponent extends BaseInput {
  @Input() maxlengthCount: number = 0;
  @Input() lengthOnlyNumber: boolean;
  @Input() control: FormControl;
  @Input() maxlength: number;
  @Input() minLength: number;
  @Input() readonly: boolean;
  @Input() autocomplete: string;

  @ViewChild('contentInput')
  set _content(_input: ElementRef<any>) {
    if (_input) {
      this.contentInput = _input;
    }
  }
  contentInput: ElementRef<any>;
  get contador() {
    return this.maxlengthCount > 0 ? this.maxlengthCount : this.maxlength;
  }
  get controlLength() {
    return this.lengthOnlyNumber ?
        this.control?.value?.toString().replace(/[^0-9]/g, '').length :
        this.control?.value?.toString().length;
  }
  get hasErrors() {
    return this.control?.errors && this.control?.touched;
  }
  get errors() {
    if (!this.control?.errors || !this.control?.touched) return [];
    return Object.keys(this.control?.errors);
  }
}
