import { Injectable } from '@angular/core';
import '@app/shared/extensions/moment.extension';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class GeradorQueryStringHelper {
  constructor() {}
  private formatDate(date: Date): string {
    return moment(date).toUtcOffset();
  }
  gerar(urlBase: string, filtro: any) {
    if (!filtro) return urlBase;
    const keysFiltered = Object.keys(filtro).filter((key) => {
      const value = filtro[key];

      if (value instanceof Date) return true;

      if (
        typeof value === 'boolean' ||
        (typeof value === 'number' && !isNaN(value))
      )
        return true;

      if (Array.isArray(value) && value.length > 0) return true;

      return !!value;
    });

    const formattedValues: string[] = keysFiltered.map((key) => {
      const value = filtro[key];
      if (value instanceof Date)
        return `${key}=${encodeURIComponent(this.formatDate(value))}`;

      if (Array.isArray(value))
        return value.map((item) => `${key}=${item}`).join('&');

      return `${key}=${value.toString()}`;
    });

    let query = `${urlBase}${
      formattedValues.length > 0 ? '?' : ''
    }${formattedValues.join('&')}`;

    if (query.endsWith('&')) query = query.slice(0, -1);

    return query;
  }
}
