<div class="cartao-contato" [ngClass]="{ principal: contato.principal }">
  <div class="icone"><i class="pi pi-user"></i></div>
  <div class="info">
    <h4 class="text-right">
      <p-tag
        [value]="contato.ativo ? 'Ativo' : 'Inativo'"
        [severity]="contato.ativo ? 'success' : 'danger'"
      ></p-tag>
    </h4>
    <h3>{{ contato.nome }}</h3>
    <h4>Email: {{ contato.email }}</h4>
    <h4>Telefone 1: {{ contato.fone1 | fone }}</h4>
    <h4>Telefone 2: {{ contato.fone2 | fone }}</h4>
    <h4>Telefone 3: {{ contato.fone3 | fone }}</h4>
  </div>
  <div class="barra-opcoes cursor-pointer w-full text-right">
    <i
      class="pi pi-star"
      *ngIf="contato.principal"
      tooltipPosition="top"
      pTooltip="Contato principal"
    ></i>
    <i
      class="pi pi-pencil ml-2"
      tooltipPosition="top"
      pTooltip="Editar contato"
      (click)="editarClicado()"
    ></i>
  </div>
</div>
