export enum AtividadeTipo {
  Nenhum,
  ComercioServicos,
  Atacado,
  Industria,
  Importador,
  ProdutorRural
}

export const AtividadeTipoLabels = new Map<number, string>([
  [AtividadeTipo.ComercioServicos, '1-COMÉRCIO E SERVIÇOS'],
  [AtividadeTipo.Atacado, '2-ATACADO'],
  [AtividadeTipo.Industria, '3-INDÚSTRIA'],
  [AtividadeTipo.Importador, '4-IMPORTADOR'],
  [AtividadeTipo.ProdutorRural, '5-PRODUTOR RURAL']
]);
