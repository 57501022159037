import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {UsuarioAuth} from '@app/abstraction/domain/entities/usuario/usuario-auth.entity';
import {Usuario} from '@app/abstraction/domain/entities/usuario/usuario.entity';
import {API} from '@app/shared/constants/api.constant';
import {lastValueFrom} from 'rxjs';

@Injectable({providedIn: 'root'})
export class UsuarioAuthService {
  constructor(private client: HttpClient) {}

  buscarUsuario(id: string) {
    const response$ = this.client.get<UsuarioAuth>(`${API.USUARIO}/${id}`);
    return lastValueFrom(response$);
  }
  cadastrarNovoUsuario(usuario: Usuario) {
    const response$ = this.client.post<UsuarioAuth>(`${API.USUARIO}`, usuario);
    return lastValueFrom(response$);
  }
}
