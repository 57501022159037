import {Injectable} from '@angular/core';
import {VersaoService} from '@app/abstraction/data/services/cadastros/versao.service';
import {VersaoStore} from '@app/abstraction/data/stores/cadastros/versao.store';
import {Versao} from '@app/abstraction/domain/entities/versao/versao.entity';
import {VersaoFiltro} from '@app/abstraction/domain/interfaces/versao/versao-filtro.interface';

@Injectable({providedIn: 'root'})
export class VersaoFacade {
  get versoes() {
    return this.store.versoes;
  }
  get versoes$() {
    return this.store.versoes$;
  }

  constructor(private store: VersaoStore, private service: VersaoService) {}

  async adicionar(versao: Versao) {
    const novaVersao = await this.service.adicionar(versao);
    if (novaVersao) {
      this.store.adicionar(novaVersao.toListagem());
    }
    return novaVersao;
  }
  alterar(versao: Versao) {
    return this.service.alterar(versao, versao.id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  async buscarVersoes(filtro?: VersaoFiltro) {
    const versoes = await this.service.buscarTodos(filtro);
    if (versoes) {
      this.store.adicionarRange(versoes);
    }
  }
  async atualizarSituacao(id: string) {
    const versao = this.versoes.find((p) => p.id === id);
    const status = await this.service.atualizarSituacao(id, !versao.ativo);
    versao.ativo = status.ativo;
    return status.ativo;
  }
  limparListaVersoes() {
    this.store.versoes = null;
  }
}
