interface String {
  isNotNumber(): boolean;
  removerAcentos(): string;
  removerCaracteresEspeciais(): string;
  removerZerosEsquerda(): string;
  somenteNumeros(): string;
  somenteLetras(): string;
  padLeft(padChar: string, size: number): string;
}
String.prototype.isNotNumber = function() {
  return isNaN(Number(this));
};
String.prototype.removerCaracteresEspeciais = function() {
  return this?.toString().replace(/[^a-z0-9]/gi, '');
};
String.prototype.removerZerosEsquerda = function() {
  return this?.toString().replace(/^(0+)(\d)/g, '$2');
};
String.prototype.somenteNumeros = function() {
  return this?.toString().replace(/\D/g, '');
};
String.prototype.somenteLetras = function() {
  return this?.toString().replace(/[^a-zA-Z]/g, '');
};
String.prototype.removerAcentos = function() {
  return this?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};
String.prototype.padLeft = function(padChar: string, size: number) {
  let s = this + '';
  while (s.length < size) s = padChar + s;
  return s;
};
