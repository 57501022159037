import {Injectable} from '@angular/core';
import {ServicoListagem} from '@app/abstraction/domain/interfaces/servico/servico-listagem.interface';
import {BehaviorSubject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ServicoStore {
  private readonly _servicos = new BehaviorSubject<ServicoListagem[]>(null);

  get servicos(): ServicoListagem[] {
    return this._servicos.getValue();
  }
  set servicos(servicos: ServicoListagem[]) {
    this._servicos.next(servicos);
  }
  get servicos$() {
    return this._servicos.asObservable();
  }
  adicionar(servico: ServicoListagem) {
    if (!this.servicos) {
      this.servicos = [];
    }
    this.servicos.push(servico);
  }
  adicionarRange(servicos: ServicoListagem[]) {
    if (!this.servicos) {
      this.servicos = [];
    }

    servicos.forEach((servico) => {
      if (!this.servicos.some((c) => c.id === servico.id)) {
        this.servicos.push(servico);
      }
    });
  }
}
