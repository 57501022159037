import { Ambiente } from '@app/shared/enums/ambiente.enum';
import { VersaoListagem } from '../../interfaces/versao/versao-listagem.interface';
import { ToastrModule } from 'ngx-toastr';
import * as moment from 'moment';
import { DetalheVersao } from '@app/shared/enums/detalhe-versao.enum';

export class Versao {
  id: string;
  servicoId: string;
  resumo: string;
  dataLancamento: Date | string;
  ambiente: string | number;
  major: number;
  minor: number;
  patch: number;
  detalhe: string | number;
  ativo: boolean;
  versaoCompleta: string;
  static from(json: any = {}): Versao {
    const versao = new Versao();
    versao.id = json.id;
    versao.servicoId = json.servicoId;
    versao.resumo = json.resumo;
    versao.dataLancamento = moment(json.dataLancamento).toDate();
    versao.ambiente = json.ambiente;
    versao.major = json.major;
    versao.minor = json.minor;
    versao.patch = json.patch;
    versao.detalhe = json.detalhe;
    versao.ativo = json.ativo;
    versao.versaoCompleta = json.versaoCompleta;
    return versao;
  }

  toAPI() {
    this.ambiente = Ambiente[Number(this.ambiente)];
    this.detalhe = DetalheVersao[Number(this.detalhe)];
    this.dataLancamento = moment(this.dataLancamento).toUtcOffset();
  }

  toListagem() {
    return <VersaoListagem>{
      id: this.id,
      servicoId: this.servicoId,
      resumo: this.resumo,
      dataLancamento: this.dataLancamento,
      ambiente: this.ambiente,
      major: this.major,
      minor: this.minor,
      patch: this.patch,
      detalhe: this.detalhe,
      ativo: this.ativo,
      versaoCompleta: this.versaoCompleta,
    };
  }
}
