<app-table-skeleton *ngIf="!rows" [rowsCount]="10"> </app-table-skeleton>

<p-table
  *ngIf="rows"
  [dataKey]="dataKey"
  [editMode]="editMode"
  responsiveLayout="scroll"
  [columns]="columns"
  [value]="rows"
  [paginator]="false"
  [showCurrentPageReport]="true"
  [dataKey]="dataKey"
>
  <ng-template pTemplate="header">
    <tr>
      <ng-container *ngFor="let col of columns">
        <th [style.width.px]="col.width" [class]="col.headerClass">
          <ng-container *ngIf="!col.headerTemplate">
            {{ col.name }}
          </ng-container>
          <ng-container *ngIf="col.headerTemplate">
            <ng-template [ngTemplateOutlet]="col.headerTemplate"></ng-template>
          </ng-container>
        </th>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-rowIndex="rowIndex"
    let-editing="editing"
    let-index="rowIndex"
  >
    <tr [pEditableRow]="rowData">
      <td *ngFor="let col of columns" [class]="col.cellClass">
        <ng-container *ngIf="col.isEditableActionsColumn">
          <td style="text-align: center">
            <button
              *ngIf="!editing"
              pButton
              pRipple
              type="button"
              pInitEditableRow
              icon="pi pi-pencil"
              (click)="onRowEditInit(rowData)"
              class="p-button-rounded p-button-text"
            ></button>
            <button
              *ngIf="editing"
              pButton
              pRipple
              type="button"
              pSaveEditableRow
              icon="pi pi-check"
              (click)="onRowEditSave(rowData)"
              class="p-button-rounded p-button-text p-button-success mr-2"
            ></button>
            <button
              *ngIf="editing"
              pButton
              pRipple
              type="button"
              pCancelEditableRow
              icon="pi pi-times"
              (click)="onRowEditCancel(rowData, index)"
              class="p-button-rounded p-button-text p-button-danger"
            ></button>
          </td>
        </ng-container>

        <ng-container *ngIf="!col.isEditableActionsColumn">
          <ng-container *ngIf="col.isEditable">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <ng-container *ngIf="compararTipoCampo(rowData.tipo, 'bool')">
                  <p-inputSwitch [(ngModel)]="rowData[col.field]"></p-inputSwitch>
                </ng-container>
                <ng-container *ngIf="compararTipoCampo(rowData.tipo, 'int')">
                  <p-inputNumber [(ngModel)]="rowData[col.field]" inputStyleClass="text-right"></p-inputNumber>
                </ng-container>
                <ng-container *ngIf="compararTipoCampo(rowData.tipo, 'string')">
                  <input pInputText [(ngModel)]="rowData[col.field]" inputStyleClass="p-inputtext-md inputfield w-full"/>
                </ng-container>
              </ng-template>
              <ng-template pTemplate="output">
                <ng-container *ngIf="!col.cellTemplate">
                  <ng-container *ngIf="compararTipoCampo(rowData.tipo, 'bool')">
                    <p-inputSwitch [(ngModel)]="rowData[col.field]" [readonly]="true"></p-inputSwitch>
                  </ng-container>
                  <ng-container *ngIf="compararTipoCampo(rowData.tipo, 'int')">
                    <p-inputNumber [(ngModel)]="rowData[col.field]" [readonly]="true" inputStyleClass="text-right"></p-inputNumber>
                  </ng-container>
                  <ng-container *ngIf="compararTipoCampo(rowData.tipo, 'string')">
                    <input pInputText [(ngModel)]="rowData[col.field]" [readonly]="true" inputStyleClass="p-inputtext-md inputfield w-full"/>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="col.cellTemplate">
                  <ng-template
                    [ngTemplateOutlet]="col.cellTemplate"
                    [ngTemplateOutletContext]="{
                      value: rowData[col.field],
                      rowIndex: rowIndex,
                      rowData: rowData
                    }"
                  >
                  </ng-template>
                </ng-container>
              </ng-template>
            </p-cellEditor>
          </ng-container>
          <ng-container *ngIf="!col.isEditable">
            <ng-container *ngIf="!col.cellTemplate">
              {{ rowData[col.field] }}
            </ng-container>
            <ng-container *ngIf="col.cellTemplate">
              <ng-template
                [ngTemplateOutlet]="col.cellTemplate"
                [ngTemplateOutletContext]="{
                  value: rowData[col.field],
                  rowIndex: rowIndex,
                  rowData: rowData
                }"
              >
              </ng-template>
            </ng-container>
          </ng-container>
        </ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="colspan" class="text-center">
        Nenhum item encontrado
      </td>
    </tr>
  </ng-template>
</p-table>
