export enum SituacaoReleaseNote {
  Pendente = 1,
  EmDesenvolvimento,
  Concluido,
}

export const SituacaoReleaseNoteLabels = new Map<number, string>([
  [SituacaoReleaseNote.Pendente, 'Pendente'],
  [SituacaoReleaseNote.EmDesenvolvimento, 'Em Desenvolvimento'],
  [SituacaoReleaseNote.Concluido, 'Concluído'],
]);
