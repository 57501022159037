export enum IeIndicador {
  Nenhum,
  ContribuinteIcms,
  Isento,
  NaoContribuinte = 9,
}

export const IeIndicadorLabels = new Map<IeIndicador, string>([
  [IeIndicador.ContribuinteIcms, '1-CONTRIBUINTE ICMS'],
  [
    IeIndicador.Isento,
    '2-CONTRIBUINTE ISENTO DE INSCRIÇÃO NO CADASTRO DE CONTRIBUINTES',
  ],
  [IeIndicador.NaoContribuinte, '9-NÃO CONTRIBUINTE'],
]);
