import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Contato } from '@app/abstraction/domain/entities/shared/contato.entity';

@Component({
  selector: 'app-cartao-contato',
  templateUrl: './cartao-contato.component.html',
  styleUrls: ['./cartao-contato.component.scss'],
})
export class CartaoContatoComponent {
  @Input() contato: Contato;
  @Output() aoCliquarEmEditar = new EventEmitter();

  editarClicado() {
    this.aoCliquarEmEditar.emit();
  }
}
