export enum TipoReleaseNote {
  Adicionado = 1,
  Alterado,
  Corrigido,
}

export const TipoReleaseNoteLabels = new Map<number, string>([
  [TipoReleaseNote.Adicionado, 'Adicionado'],
  [TipoReleaseNote.Alterado, 'Alterado'],
  [TipoReleaseNote.Corrigido, 'Corrigido'],
]);
