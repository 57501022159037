import '@app/shared/extensions/array.extension';

import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {UsuarioAuthStore} from '@app/abstraction/data/stores/shared/usuario-auth.store';
import {ErrorMessageFacade} from '@app/abstraction/domain/facades/shared/error-message.facade';
import {UsuarioAuthFacade} from '@app/abstraction/domain/facades/shared/usuario-auth.facade';
import {authConfig} from '@app/core/auth/config';
import {OAuthService} from 'angular-oauth2-oidc';
import {JwksValidationHandler} from 'angular-oauth2-oidc-jwks';
import {from} from 'rxjs';

@Injectable({providedIn: 'root'})
export class OAuthFacade {
  constructor(
      private oauthService: OAuthService,
      private authStore: UsuarioAuthStore,
      private authFacade: UsuarioAuthFacade,
      private router: Router,
      private errorMessageFacade: ErrorMessageFacade,
  ) {}
  get isLoggedIn() {
    return (
        this.oauthService.hasValidIdToken() &&
        this.oauthService.hasValidAccessToken());
  }
  async configurarSingleSignOn() {
    this.oauthService.configure(authConfig);
    this.oauthService.setStorage(localStorage);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    await this.loadDiscoveryDocumentAndTryLogin();

    if (this.isLoggedIn) {
      await this.router.navigate(['/']);
    } else {
      this.iniciarLogin();
    }
  }
  async cadastrarNovoUsuario() {
    const userProfile = (await this.oauthService.loadUserProfile()) as any;

    await this.authFacade.buscarUsuarioLogado(userProfile.info.sub)
        .catch(async (e: any) => {
          if (e instanceof HttpErrorResponse && e.status === 404) {
            // const novoUsuario = <Usuario>{
            //   usuarioId: userProfile.info.sub,
            //   nome: userProfile.info.name,
            //   email: userProfile.info.email,
            //   senha: '12345678',
            //   ativo: true
            // };
            // await this.authFacade.cadastrarNovoUsuario(novoUsuario);
          } else if (e instanceof HttpErrorResponse && e.status === 403) {
            this.errorMessageFacade.mostrarMensagens(
                'Você não tem permissão para acessar o G3Manager');
            setTimeout(() => {
              this.logout();
            }, 1000);
          }
        });
  }
  loadDiscoveryDocumentAndTryLogin(): any {
    try {
      return this.oauthService.loadDiscoveryDocumentAndTryLogin();
    } catch {
    }
  }
  iniciarLogin() {
    this.oauthService.initLoginFlow();
  }
  logout() {
    this.oauthService.logOut();
    this.authStore.usuarioLogado = null;
    this.router.navigate(['/']);
  }
  refreshToken() {
    return from(this.oauthService.refreshToken());
  }
}
