import {ModuloListagem} from '../../interfaces/modulo/modulo-listagem.interface';
import {Tela} from '../tela/tela.entity';

export class Modulo {
  id: string;
  descricao: string;
  rota: string;
  icone: string;
  telas: Tela[];
  ativo: boolean;

  static from(json: any = {}): Modulo {
    const modulo = new Modulo();
    modulo.descricao = json.descricao;
    modulo.ativo = json.ativo;
    modulo.id = json.id;
    modulo.rota = json.rota;
    modulo.icone = json.icone;
    modulo.telas = json.telas;
    return modulo;
  }

  toListagem() {
    return <ModuloListagem> {
      id: this.id, descricao: this.id, rota: this.rota, icone: this.icone,
          telas: this.telas, ativo: this.ativo,
    }
  }
}
