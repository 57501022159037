import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'documento',
})
export class CpfCnpjPipe implements PipeTransform {
  private cnpjFormat(result: string): string {
    return `${result.slice(0, 2)}.${result.slice(2, 5)}.${result.slice(
      5,
      8
    )}/${result.slice(8, 12)}-${result.slice(12, 14)}`;
  }
  private cpfFormat(result: string): string {
    return `${result.slice(0, 3)}.${result.slice(3, 6)}.${result.slice(
      6,
      9
    )}-${result.slice(9, 11)}`;
  }
  transform(documento: string | number): string {
    documento = documento?.toString();
    if (documento) {
      if (documento.length > 11) {
        return this.cnpjFormat(documento);
      } else {
        return this.cpfFormat(documento);
      }
    }
    return '';
  }
}
