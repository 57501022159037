import { UntypedFormGroup } from '@angular/forms';
import { ErrorMessageFacade } from '@app/abstraction/domain/facades/shared/error-message.facade';
import { OperacaoForm } from '@app/shared/enums/operacao-form.enum';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from './base.component';

export class FormBaseComponent extends BaseComponent {
  operacaoForm: OperacaoForm;
  form: UntypedFormGroup;
  constructor(
    toastrService: ToastrService,
    errorMessagesFacade?: ErrorMessageFacade
  ) {
    super(toastrService, errorMessagesFacade);
  }
  get operacaoEditar() {
    return this.operacaoForm === OperacaoForm.Editar;
  }
}
