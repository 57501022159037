<div class="layout-footer">
  <img
    src="assets/imagens/{{
      layoutService.config().colorScheme === 'light'
        ? 'g3-logo-dark'
        : 'g3-logo-light'
    }}.png"
    alt="Logo"
    height="20"
    class="mr-2"
  />
  by
  <span class="font-medium ml-2">G3Soft Tecnologia</span>
</div>
