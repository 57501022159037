<ul class="layout-menu">
  <ng-container *ngFor="let item of model; let i = index">
    <li
      app-menuitem
      *ngIf="!item.separator"
      [item]="item"
      [index]="i"
      [root]="true"
    ></li>
    <li *ngIf="item.separator" class="menu-separator"></li>
  </ng-container>
  <li class="mt-4 text-center">
    <small class="semibold tc-primary" *ngIf="homologacao">Homologação</small>
  </li>
  <li class="text-center">
    <small class="bold tc-primary">{{ version }}</small>
  </li>
  <li class="text-center">
    <small class="semibold tc-primary">{{ dataAtualizacao }}</small>
  </li>
</ul>
