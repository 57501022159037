import {AfterViewInit, Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appCalendarMask]',
})
export class CalendarMaskDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    const input = this.elementRef.nativeElement.querySelector('input');
    input.addEventListener('keydown', (event: any) => {
      if (event.key !== 'Backspace' && event.currentTarget.value.length > 9) {
        event.preventDefault();
      }
      if (event.key !== 'Backspace' &&
          (event.currentTarget.value.length === 2 ||
           event.currentTarget.value.length === 5)) {
        event.currentTarget.value += '/';
      }
    });
  }
}
