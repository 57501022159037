import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { MenuTopOpcoes } from './interfaces/menu-top-options.interface';
import { TableColumn } from '@app/shared/interfaces/components/table-column.interface';

@Component({
  selector: 'app-menu-top',
  templateUrl: './menu-top.component.html',
  styleUrls: ['./menu-top.component.scss'],
})
export class MenuTopComponent {
  @ContentChild('buscaAvancada') buscaAvancada: TemplateRef<ElementRef>;
  @Input() opcoes: MenuTopOpcoes;
  @Input() colunasOcultas: TableColumn[];
  @Input() variosSelecionados: boolean;
  @Output() aoFiltrar = new EventEmitter<any>();
  @Output() aoSalvar = new EventEmitter();
  @Output() atualizarColunasCallBack = new EventEmitter<any>();

  displayBuscaAvancada = false;
  displayMenuColunasOcultas = false;

  get botoesListaComItens() {
    return this.opcoes?.botoes?.length > 0;
  }

  mostrarBuscarAvancada() {
    this.displayBuscaAvancada = !this.displayBuscaAvancada;
    let div = document.getElementById('busca-avancada');
    if (this.displayBuscaAvancada) {
      div.classList.add('show');
    } else {
      div.classList.remove('show');
    }
  }
  mostrarMenuColunasOcultas() {
    this.displayMenuColunasOcultas = !this.displayMenuColunasOcultas;
    let div = document.getElementById('colunas-ocultas');
    if (this.displayMenuColunasOcultas) {
      div.classList.add('show');
    } else {
      div.classList.remove('show');
    }
  }
  tipoMenu(tipo: string) {
    return this.opcoes?.tipo === tipo;
  }
  filtrar(event: any) {
    this.aoFiltrar.emit(event.target.value);
  }
  adicionarRemoverColuna(coluna: string | number, event: any) {
    this.colunasOcultas.find((e) => e.field === coluna).hidden = !event.checked;
  }
  salvarClicado() {
    this.aoSalvar.emit();
  }
}
