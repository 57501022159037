import { Pipe, PipeTransform } from '@angular/core';
import { ServicoFacade } from '@app/abstraction/domain/facades/shared/servico.facade';
import { ServicoListagem } from '@app/abstraction/domain/interfaces/servico/servico-listagem.interface';

@Pipe({
  name: 'servico',
})
export class ServicoPipe implements PipeTransform {
  constructor(private servicoFacade: ServicoFacade) {}
  transform(id: string, campo: string = 'descricao'): any {
    if (id) {
      const servico = this.servicoFacade.servicos?.find(
        (servico: ServicoListagem) => servico.id == id
      ) as any;
      return servico?.[campo];
    }
    return '';
  }
}
