import {Injectable} from '@angular/core';
import {ModuloService} from '@app/abstraction/data/services/cadastros/modulo.service';
import {ModuloStore} from '@app/abstraction/data/stores/cadastros/modulo.store';
import {Modulo} from '@app/abstraction/domain/entities/modulo/modulo.entity';
import {ModuloFiltro} from '@app/abstraction/domain/interfaces/modulo/modulo-filtro.interface';

@Injectable({providedIn: 'root'})
export class ModuloFacade {
  get modulos() {
    return this.store.modulos;
  }
  get modulos$() {
    return this.store.modulos$;
  }

  constructor(private store: ModuloStore, private service: ModuloService) {}

  async adicionar(modulo: Modulo) {
    const novoModulo = await this.service.adicionar(modulo);
    if (novoModulo) {
      this.store.adicionar(novoModulo.toListagem());
    }
    return novoModulo;
  }
  alterar(modulo: Modulo) {
    return this.service.alterar(modulo, modulo.id);
  }
  buscarPorId(id: string) {
    return this.service.buscarPorId(id);
  }
  async atualizarSituacao(id: string) {
    const modulo = this.modulos.find((p) => p.id === id);
    const situacao = await this.service.atualizarSituacao(id, !modulo.ativo);
    modulo.ativo = situacao.ativo;
    return situacao.ativo;
  }
  async buscarModulos(filtro?: ModuloFiltro) {
    const modulos = await this.service.buscarTodos(filtro);
    if (modulos) {
      this.store.adicionarRange(modulos);
    }
  }
  limparListaModulos() {
    this.store.modulos = null;
  }
}
