import {CommonModule, DatePipe} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {ColorPickerModule} from 'ngx-color-picker';
import {NgxCurrencyDirective} from 'ngx-currency';
import {ImageCropperModule} from 'ngx-image-cropper';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {NgxMaskDirective, NgxMaskPipe, provideNgxMask} from 'ngx-mask';
import {AccordionModule} from 'primeng/accordion';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {ChartModule} from 'primeng/chart'
import {CheckboxModule} from 'primeng/checkbox';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {FileUploadModule} from 'primeng/fileupload';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MultiSelectModule} from 'primeng/multiselect';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ScrollTopModule} from 'primeng/scrolltop';
import {SkeletonModule} from 'primeng/skeleton';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TagModule} from 'primeng/tag';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeTableModule} from 'primeng/treetable';

import {CardComponent} from './components/dumb/card/card.component';
import {CartaoContatoComponent} from './components/dumb/cartao-contato/cartao-contato.component';
import {CartaoEnderecoComponent} from './components/dumb/cartao-endereco/cartao-endereco.component';
import {CustomLabelComponent} from './components/dumb/custom-label/custom-label.component';
import {CalendarInputComponent} from './components/dumb/form-inputs/calendar-input/calendar-input.component';
import {CheckboxInputComponent} from './components/dumb/form-inputs/checkbox-input/checkbox-input.component';
import {DropdownInputComponent} from './components/dumb/form-inputs/dropdown-input/dropdown-input.component';
import {MultiselectInputComponent} from './components/dumb/form-inputs/multiselect-input/multiselect-input.component';
import {NumericInputComponent} from './components/dumb/form-inputs/numeric-input/numeric-input.component';
import {PasswordInputComponent} from './components/dumb/form-inputs/password-input/password-input.component';
import {TextInputComponent} from './components/dumb/form-inputs/text-input/text-input.component';
import {TextareaInputComponent} from './components/dumb/form-inputs/textarea-input/textarea-input.component';
import {IconPickerComponent} from './components/dumb/icon-picker/icon-picker.component';
import {MenuAcoesMassaComponent} from './components/dumb/menu-acoes-massa/menu-acoes-massa.component';
import {MenuTopComponent} from './components/dumb/menu-top/menu-top.component';
import {MultiSelectColunasComponent} from './components/dumb/multi-select-colunas/multi-select-colunas.component';
import {DropdownInputNgmodelComponent} from './components/dumb/ngmodel-inputs/dropdown-input-ngmodel/dropdown-input-ngmodel.component';
import {MultiselectInputNgModelComponent} from './components/dumb/ngmodel-inputs/multiselect-input-ngmodel/multiselect-input-ngmodel.component';
import {TextInputNgModelComponent} from './components/dumb/ngmodel-inputs/text-input-ngmodel/text-input-ngmodel.component';
import {TableSkeletonComponent} from './components/dumb/skeletons/table-skeleton.component';
import {TabelaParametrosComponent} from './components/dumb/tabela-parametros/tabela-parametros.component';
import {TableComponent} from './components/dumb/table/table.component';
import {ContatoModalComponent} from './components/smart/contato-modal/contato-modal.component';
import {EnderecoModalComponent} from './components/smart/endereco-modal/endereco-modal.component';
import {TitleComponent} from './components/smart/title/title.component';
import {TituloComponent} from './components/smart/titulo/titulo.component';
import {AutoFocusDirective} from './directives/auto-focus/auto-focus.directive';
import {CalendarMaskDirective} from './directives/calendar-mask/calendar-mask.directive';
import {FoneMaskDirective} from './directives/fone-mask/fone-mask.directive';
import {UppercaseDirective} from './directives/uppercase-value-accessor/uppercase.directive';
import {CepPipe} from './pipes/cep.pipe';
import {CpfCnpjPipe} from './pipes/cpf-cnpj.pipe';
import {customDecimalPipe} from './pipes/custom-decimal.pipe';
import {EnderecoTipoPipe} from './pipes/endereco-tipo.pipe';
import {EnumLabelPipe} from './pipes/enum-label.pipe';
import {EstadoPipe} from './pipes/estado.pipe';
import {FonePipe} from './pipes/fone.pipe';
import {ModuloPipe} from './pipes/modulo.pipe';
import {PaisPipe} from './pipes/pais.pipe';
import {ServicoPipe} from './pipes/servico.pipe';
import {VersaoPipe} from './pipes/versao.pipe';

@NgModule({
  imports: [...SharedModule.importModules()],
  declarations: [
    ...SharedModule.declareComponents(),
    ...SharedModule.declareDirectives(),
    ...SharedModule.declarePipes(),
  ],
  exports: [
    ...SharedModule.exportModules(),
    ...SharedModule.exportComponents(),
    ...SharedModule.exportDirectives(),
    ...SharedModule.exportPipes(),
  ],
  providers: [...SharedModule.providePipes(), provideNgxMask()],
})
export class SharedModule {
  static importModules() {
    return [
      CommonModule,        FormsModule,         ReactiveFormsModule,
      RouterModule,        DividerModule,       ButtonModule,
      PasswordModule,      ScrollTopModule,     InputTextModule,
      InputNumberModule,   ToolbarModule,       AvatarModule,
      DialogModule,        BreadcrumbModule,    RadioButtonModule,
      CardModule,          CheckboxModule,      TooltipModule,
      PanelModule,         TableModule,         TagModule,
      SkeletonModule,      MenuModule,          AvatarGroupModule,
      MegaMenuModule,      AccordionModule,     TabViewModule,
      DropdownModule,      MultiSelectModule,   PanelMenuModule,
      TreeTableModule,     NgxJsonViewerModule, CalendarModule,
      FileUploadModule,    ImageCropperModule,  InputSwitchModule,
      ConfirmDialogModule, NgxMaskDirective,    NgxMaskPipe,
      ColorPickerModule,   InputTextareaModule, TieredMenuModule,
      ChartModule,         NgxCurrencyDirective
    ];
  }
  static declareComponents() {
    return [
      TitleComponent,
      MenuTopComponent,
      TableComponent,
      IconPickerComponent,
      CardComponent,
      TableSkeletonComponent,
      MenuAcoesMassaComponent,
      MultiSelectColunasComponent,
      CustomLabelComponent,
      CartaoContatoComponent,
      CartaoEnderecoComponent,
      EnderecoModalComponent,
      ContatoModalComponent,
      TabelaParametrosComponent,
      TextInputComponent,
      PasswordInputComponent,
      DropdownInputComponent,
      CalendarInputComponent,
      TextareaInputComponent,
      MultiselectInputComponent,
      DropdownInputNgmodelComponent,
      TextInputNgModelComponent,
      CheckboxInputComponent,
      TituloComponent,
      MultiselectInputNgModelComponent,
      NumericInputComponent
    ];
  }
  static declareDirectives() {
    return [
      FoneMaskDirective, CalendarMaskDirective, UppercaseDirective,
      AutoFocusDirective
    ];
  }
  static declarePipes() {
    return [
      EnumLabelPipe,
      CpfCnpjPipe,
      EstadoPipe,
      PaisPipe,
      CepPipe,
      EnderecoTipoPipe,
      FonePipe,
      ServicoPipe,
      VersaoPipe,
      ModuloPipe,
      customDecimalPipe,
    ];
  }
  static exportModules() {
    return [
      RouterModule,        CommonModule,        FormsModule,
      ReactiveFormsModule, DividerModule,       ButtonModule,
      PasswordModule,      ScrollTopModule,     InputTextModule,
      InputNumberModule,   ToolbarModule,       AvatarModule,
      DialogModule,        BreadcrumbModule,    RadioButtonModule,
      CardModule,          CheckboxModule,      TooltipModule,
      PanelModule,         TableModule,         TagModule,
      SkeletonModule,      AccordionModule,     TabViewModule,
      DropdownModule,      MultiSelectModule,   PanelMenuModule,
      TreeTableModule,     NgxJsonViewerModule, CalendarModule,
      FileUploadModule,    ImageCropperModule,  InputSwitchModule,
      ConfirmDialogModule, NgxMaskDirective,    NgxMaskPipe,
      ColorPickerModule,   InputTextareaModule, TieredMenuModule,
      ChartModule,         NgxCurrencyDirective
    ];
  }
  static exportComponents() {
    return [
      TitleComponent,
      MenuTopComponent,
      TableComponent,
      IconPickerComponent,
      CardComponent,
      TableSkeletonComponent,
      MenuAcoesMassaComponent,
      MultiSelectColunasComponent,
      CustomLabelComponent,
      CartaoContatoComponent,
      CartaoEnderecoComponent,
      EnderecoModalComponent,
      ContatoModalComponent,
      TabelaParametrosComponent,
      TextInputComponent,
      PasswordInputComponent,
      DropdownInputComponent,
      CalendarInputComponent,
      TextareaInputComponent,
      MultiselectInputComponent,
      DropdownInputNgmodelComponent,
      TextInputNgModelComponent,
      CheckboxInputComponent,
      TituloComponent,
      MultiselectInputNgModelComponent,
      NumericInputComponent,
    ];
  }
  static exportDirectives() {
    return [
      FoneMaskDirective, CalendarMaskDirective, UppercaseDirective,
      AutoFocusDirective
    ];
  }
  static exportPipes() {
    return [
      EnumLabelPipe,
      CpfCnpjPipe,
      EstadoPipe,
      PaisPipe,
      CepPipe,
      EnderecoTipoPipe,
      FonePipe,
      ServicoPipe,
      VersaoPipe,
      ModuloPipe,
      customDecimalPipe,
    ];
  }
  static providePipes() {
    return [DatePipe, ServicoPipe, VersaoPipe, customDecimalPipe];
  }
}
