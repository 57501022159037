import {Pipe, PipeTransform} from '@angular/core';
import {Modulo} from '@app/abstraction/domain/entities/modulo/modulo.entity';
import {ModuloFacade} from '@app/abstraction/domain/facades/cadastros/modulo/modulo-cadastro.facade';
import {ModuloListagem} from '@app/abstraction/domain/interfaces/modulo/modulo-listagem.interface';

@Pipe({
  name: 'modulo',
})
export class ModuloPipe implements PipeTransform {
  constructor(private moduloFacade: ModuloFacade) {}
  transform(id: string): any {
    if (id) {
      const modulo = this.moduloFacade.modulos?.find(
                         (modulo: ModuloListagem) => modulo.id == id) as Modulo;
      return modulo?.descricao;
    }
    return '';
  }
}
