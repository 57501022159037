import {environment} from 'environments/environment';

export class API {
  static readonly MANAGER_BASE_URL = environment.apiBaseUrl;
  static readonly BASE_URL_MOCK = 'http://localhost:3000';

  /* #region  Usuário */
  static readonly USUARIO = 'usuario';
  /* #endregion */

  /* #region  Planos */
  static readonly PLANO = 'plano';
  /* #endregion */

  /* #region  Planos */
  static readonly ASSINANTE = 'assinante';
  /* #endregion */

  /* #region  Módulos */
  static readonly DASHBOARD = 'dashboard';
  static readonly API_KEY = 'apikey';
  static readonly MODULO = 'modulo';
  static readonly ACAO = 'acao';
  static readonly TELA = 'tela';
  static readonly CARTEIRA = 'carteira';
  static readonly PARAMETRO = 'erp/parametro';
  static readonly EMPRESA = 'empresa';
  static readonly EMPRESA_DISPONIBILIDADE = 'empresa/disponibilidade';
  static readonly VERSAO = `versao`;
  static readonly RELEASE_NOTE = `release-note`;
  static readonly INSTITUICAO_BANCARIA = 'instituicao-bancaria';
  /* #endregion */

  /* #region  Status */
  static readonly STATUS = 'status';
  /* #endregion */

  /* #region Endereco Tipo */
  static readonly PESSOA_FISICA = 'pessoa/fisica';
  static readonly PESSOA_JURIDICA = 'pessoa/juridica';

  /* #endregion */

  /* #region Endereco Tipo */
  static readonly ENDERECO_TIPOS = 'endereco/tipos';

  /* #endregion */

  /* #region Versão */
  static readonly SERVICO = `servico`;
  /* #endregion */

  /* #region Versão */
  static readonly DADOS = 'dados';
  /* #endregion */
}
