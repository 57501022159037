import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { BaseInput } from '../base/base-input';

@Component({
  selector: 'app-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss'],
})
export class TextareaInputComponent extends BaseInput {
  @Input() upperCase: boolean = true;
  @Input() autoFocus: boolean;
  @Input() maxlengthCount: number = 0;
  @Input() lengthOnlyNumber: boolean;
  @Input() textAlign: string = 'left'; // left, right, center
  @Input() fullWidth: boolean; // left, right, center
  @Input() control: FormControl;
  @Input() placeholder: string = '';
  @Input() readonly: boolean;
  @Input() maxlength: number;
  @Input() rows: number;
  @Output() inputBlur = new EventEmitter();
  @Output() inputKeydown = new EventEmitter();

  @ViewChild('contentInput')
  set _content(_input: ElementRef<any>) {
    if (_input) {
      this.contentInput = _input;
    }
  }
  contentInput: ElementRef<any>;
  get contador() {
    return this.maxlengthCount > 0 ? this.maxlengthCount : this.maxlength;
  }
  get controlLength() {
    return this.lengthOnlyNumber
      ? this.control?.value?.toString().replace(/[^0-9]/g, '').length
      : this.control?.value?.toString().length;
  }
  get hasErrors() {
    return this.control?.errors && this.control?.touched;
  }
  get errors() {
    if (!this.control?.errors || !this.control?.touched) return [];
    return Object.keys(this.control?.errors);
  }
  onBlur(event: any) {
    this.inputBlur.emit(event);
  }
  onKeyDown(event: any) {
    this.inputKeydown.emit(event);
  }
}
