import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AppLayoutComponent} from './modules/layout/app.layout.component';
import {usuarioLogado, usuarioNaoLogado} from './shared/guards/auth/auth.guard';


const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
            import('./modules/home/home.module').then((m) => m.HomeModule),
      },
    ],
    canActivate: [usuarioLogado],
  },
  {
    path: 'auth',
    loadChildren: () =>
        import('./modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [usuarioNaoLogado],

  },
];

@NgModule({imports: [RouterModule.forRoot(routes)], exports: [RouterModule]})
export class AppRoutingModule {
}
