import {Component, OnInit} from '@angular/core';
import {environment} from 'environments/environment';

import {LayoutService} from './service/app.layout.service';

@Component({selector: 'app-menu', templateUrl: './app.menu.component.html'})
export class AppMenuComponent implements OnInit {
  model: any[] = [];
  get version() {
    return environment.version;
  }
  get dataAtualizacao() {
    return environment.dataAtualizacao;
  }
  get homologacao() {
    return !environment.production;
  }
  constructor(public layoutService: LayoutService) {}

  ngOnInit() {
    this.model = [
      {
        label: 'Home',
        items: [
          {
            label: 'Dashboard',
            icon: 'pi pi-fw pi-home',
            routerLink: ['/dashboard'],
          },
        ],
      },
      {
        label: 'Assinatura',
        items: [
          {
            label: 'Planos',
            icon: 'pi pi-fw pi-file-edit',
            routerLink: ['/cadastros/planos'],
          },
          {
            label: 'Assinantes',
            icon: 'pi pi-fw pi-user-edit',
            routerLink: ['/assinaturas/assinantes'],
          },
        ],
      },
      {
        label: 'Cadastros',
        items: [
          {
            label: 'Carteiras',
            icon: 'pi pi-fw pi-wallet',
            routerLink: ['/cadastros/carteiras'],
          },
          {
            label: 'Empresas',
            icon: 'pi pi-fw pi-building',
            routerLink: ['/cadastros/empresas'],
          },
          {
            label: 'Instituições Bancárias',
            icon: 'pi pi-fw pi-building',
            routerLink: ['/cadastros/instituicoes-bancarias'],
          },
          {
            label: 'Módulos',
            icon: 'pi pi-fw pi-th-large',
            routerLink: ['/cadastros/modulos'],
          },

          {
            label: 'Parâmetros',
            icon: 'pi pi-fw pi-cog',
            routerLink: ['/cadastros/parametros'],
          },
          {
            label: 'Release Notes',
            icon: 'pi pi-fw pi-list',
            routerLink: ['/cadastros/release-notes'],
          },
          {
            label: 'Telas',
            icon: 'pi pi-fw pi-desktop',
            routerLink: ['/cadastros/telas'],
          },
          {
            label: 'Usuários',
            icon: 'pi pi-fw pi-users',
            routerLink: ['/cadastros/usuarios'],
          },
          {
            label: 'Versões',
            icon: 'pi pi-fw pi-tag',
            routerLink: ['/cadastros/versoes'],
          },
        ],
      },
      {
        label: 'Relatórios',
        items: [
          {
            label: 'Uso de Dados',
            icon: 'pi pi-fw pi-database',
            routerLink: ['/relatorios/uso-de-dados'],
          },
        ],
      },
    ];
  }
}
