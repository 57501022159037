<p-multiSelect
  *ngIf="mostrarMultiSelect"
  [maxSelectedLabels]="1"
  appendTo="body"
  styleClass="p-inputtext-md inputfield w-full ml-auto"
  [options]="colunasOcultas"
  optionLabel="name"
  selectedItemsLabel="{0} itens selecionados"
  placeholder="Mostrar colunas"
  emptyMessage="Nenhum item encontrado"
  emptyFilterMessage="Nenhum item encontrado"
  (onChange)="emitirChange($event)"
  (onSelectAllChange)="emitirChange($event)"
>
</p-multiSelect>
