import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enumLabel',
})
export class EnumLabelPipe implements PipeTransform {
  transform(valor: any, labels: Map<number, string>): string {
    if (valor != null && valor != undefined && labels) {
      return labels.get(Number(valor));
    }
    return '';
  }
}
