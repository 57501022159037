import '@app/shared/extensions/array.extension';

import {Injectable} from '@angular/core';
import {UsuarioAuthService} from '@app/abstraction/data/services/shared/usuario-auth.service';
import {UsuarioAuthStore} from '@app/abstraction/data/stores/shared/usuario-auth.store';
import {Usuario} from '@app/abstraction/domain/entities/usuario/usuario.entity';
import {Favorito} from '@app/shared/interfaces/components/favorito.interface';

@Injectable({providedIn: 'root'})
export class UsuarioAuthFacade {
  get usuarioLogado() {
    return this.store.usuarioLogado;
  }
  constructor(
      private store: UsuarioAuthStore,
      private service: UsuarioAuthService,
  ) {}

  async buscarUsuarioLogado(userId: string) {
    this.store.usuarioLogado = await this.service.buscarUsuario(userId);
    return this.usuarioLogado;
  }
  async cadastrarNovoUsuario(usuario: Usuario) {
    const novoUsuario = await this.service.cadastrarNovoUsuario(usuario);
    if (novoUsuario) {
      this.store.usuarioLogado = novoUsuario;
    }
  }
  adicionarFavorito(favorito: Favorito) {
    if (!this.usuarioLogado.favoritos) {
      this.store.usuarioLogado.favoritos = [];
    }
    this.store.adicionarFavorito(favorito);
  }
  removerFavorito(favorito: Favorito) {
    this.store.removerFavorito(favorito);
  }
}
