import { Pipe, PipeTransform } from '@angular/core';
import { PaisConst, Paises } from '@app/shared/constants/paises.constant';

@Pipe({
  name: 'pais',
})
export class PaisPipe implements PipeTransform {
  transform(paisIbge: string | number, campo: string = 'paisDescricao') {
    if (paisIbge) {
      const pais = Paises.find(
        (pais: PaisConst) =>
          pais.paisIbge.toString() === paisIbge.toString().padStart(4, '0')
      ) as any;

      return pais?.[campo];
    }
    return '';
  }
}
