import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Status} from '@app/abstraction/domain/entities/status/status.entity';
import {Versao} from '@app/abstraction/domain/entities/versao/versao.entity';
import {VersaoFiltro} from '@app/abstraction/domain/interfaces/versao/versao-filtro.interface';
import {VersaoListagem} from '@app/abstraction/domain/interfaces/versao/versao-listagem.interface';
import {API} from '@app/shared/constants/api.constant';
import {GeradorQueryStringHelper} from '@app/shared/helpers/gerador-query-string.helper';
import {lastValueFrom, map} from 'rxjs';

@Injectable({providedIn: 'root'})
export class VersaoService {
  constructor(
      private geradorQueryString: GeradorQueryStringHelper,
      private client: HttpClient,
  ) {}

  buscarTodos(filtro?: VersaoFiltro) {
    const response$ = this.client.get<VersaoListagem[]>(
        this.geradorQueryString.gerar(`${API.VERSAO}`, filtro));
    return lastValueFrom(response$);
  }
  adicionar(dados: Versao) {
    const response$ =
        this.client.post<Versao>(API.VERSAO, dados)
            .pipe(map((json: Versao) => json ? Versao.from(json) : null));
    return lastValueFrom(response$);
  }
  alterar(dados: Versao, id: string) {
    const response$ =
        this.client.put<Versao>(`${API.VERSAO}/${id}`, dados)
            .pipe(map((json: Versao) => json ? Versao.from(json) : null));
    return lastValueFrom(response$);
  }
  atualizarSituacao(id: string, situacao: boolean) {
    const response$ =
        this.client.put<Status>(`${API.VERSAO}/${id}/${API.STATUS}`, situacao);
    return lastValueFrom(response$);
  }
  buscarPorId(id: string) {
    const response$ =
        this.client.get<Versao>(`${API.VERSAO}/${id}`)
            .pipe(map((json: Versao) => json ? Versao.from(json) : null));
    return lastValueFrom(response$);
  }
}
