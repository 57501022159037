export class EstadoConst {
  constructor(_ufIbge: number, _ufDescricao: string, _ufSigla: string) {
    this.ufSigla = _ufSigla;
    this.ufDescricao = _ufDescricao;
    this.ufIbge = _ufIbge;
  }
  ufSigla: string;
  ufDescricao: string;
  ufIbge: number;
}

export const Estados = [
  new EstadoConst(11, 'RONDONIA', 'RO'),
  new EstadoConst(12, 'ACRE', 'AC'),
  new EstadoConst(13, 'AMAZONAS', 'AM'),
  new EstadoConst(14, 'RORAIMA', 'RR'),
  new EstadoConst(15, 'PARA', 'PA'),
  new EstadoConst(16, 'AMAPA', 'AP'),
  new EstadoConst(17, 'TOCANTINS', 'TO'),
  new EstadoConst(21, 'MARANHAO', 'MA'),
  new EstadoConst(22, 'PIAUI', 'PI'),
  new EstadoConst(23, 'CEARA', 'CE'),
  new EstadoConst(24, 'RIO GRANDE DO NORTE', 'RN'),
  new EstadoConst(25, 'PARAIBA', 'PB'),
  new EstadoConst(26, 'PERNAMBUCO', 'PE'),
  new EstadoConst(27, 'ALAGOAS', 'AL'),
  new EstadoConst(28, 'SERGIPE', 'SE'),
  new EstadoConst(29, 'BAHIA', 'BA'),
  new EstadoConst(31, 'MINAS GERAIS', 'MG'),
  new EstadoConst(32, 'ESPIRITO SANTO', 'ES'),
  new EstadoConst(33, 'RIO DE JANEIRO', 'RJ'),
  new EstadoConst(35, 'SAO PAULO', 'SP'),
  new EstadoConst(41, 'PARANA', 'PR'),
  new EstadoConst(42, 'SANTA CATARINA', 'SC'),
  new EstadoConst(43, 'RIO GRANDE DO SUL', 'RS'),
  new EstadoConst(50, 'MATO GROSSO DO SUL', 'MS'),
  new EstadoConst(51, 'MATO GROSSO', 'MT'),
  new EstadoConst(52, 'GOIAS', 'GO'),
  new EstadoConst(53, 'DISTRITO FEDERAL', 'DF'),
];
