<ng-container *ngIf="control">
  <div class="field-checkbox mt-4">
    <p-checkbox
      class="mr-2"
      #contentInput
      [formControl]="control"
      [inputId]="controlName"
      [binary]="true"
      [readonly]="readonly"
      [value]="value"
      (onChange)="onChange($event)"
    ></p-checkbox>
    <app-custom-label [for]="controlName" [texto]="label" class="mt-2"></app-custom-label>
  </div>
</ng-container>
