import {Injectable} from '@angular/core';
import {EnderecoTipo} from '@app/abstraction/domain/interfaces/shared/endereco-tipo.interface';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class EnderecoTipoStore {
  private readonly _enderecoTipos = new BehaviorSubject<EnderecoTipo[]>(null);
  get enderecoTipos() {
    return this._enderecoTipos.getValue();
  }
  set enderecoTipos(enderecoTipos: EnderecoTipo[]) {
    this._enderecoTipos.next(enderecoTipos);
  }
  get enderecoTipos$(): Observable<EnderecoTipo[]> {
    return this._enderecoTipos.asObservable();
  }
  adicionarRange(enderecoTipos: EnderecoTipo[]) {
    if (!this.enderecoTipos) {
      this.enderecoTipos = [];
    }

    enderecoTipos.forEach((enderecoTipo) => {
      if (!this.enderecoTipos.some(
              (c) => c.enderecoTipoId === enderecoTipo.enderecoTipoId)) {
        this.enderecoTipos.push(enderecoTipo);
      }
    });
  }
}
