import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ModuloListagem} from '@app/abstraction/domain/interfaces/modulo/modulo-listagem.interface';
import {ParametroListagem} from '@app/abstraction/domain/interfaces/parametro/parametro-listagem.interface';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';

@Component({
  selector: 'app-tabela-parametros',
  templateUrl: './tabela-parametros.component.html',
})
export class TabelaParametrosComponent {
  @Input() columns: TableColumn[];
  @Input() dataKey: string;
  @Input() rows: ParametroListagem[];
  @Input() editMode: 'cell'|'row' = 'row';
  @Output() aoSalvarLinha: EventEmitter<ParametroListagem> = new EventEmitter();

  clonedItems: {[s: string]: ModuloListagem;} = {};
  get colspan() {
    let count = this.columns?.filter((e) => !e.hidden).length;
    return count;
  }
  constructor() {}
  compararTipoCampo(tipoCampo: string, tipo: string) {
    return tipoCampo.toLowerCase() === tipo;
  }
  onRowEditInit(parametro: ParametroListagem) {
    this.clonedItems[parametro.chave] = {...parametro} as any;
  }
  onRowEditSave(parametro: ParametroListagem) {
    this.aoSalvarLinha.emit(parametro);
  }
  onRowEditCancel(row: ParametroListagem, index: number) {
    this.rows[index] = this.clonedItems[row.chave] as any;
    delete this.clonedItems[row.chave];
  }
}
