import { Injectable } from '@angular/core';
import { EnderecoTipoService } from '@app/abstraction/data/services/shared/endereco-tipo.service';
import { EnderecoTipoStore } from '@app/abstraction/data/stores/shared/endereco-tipo.store';
import { EnderecoTipo } from '@app/abstraction/domain/interfaces/shared/endereco-tipo.interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EnderecoTipoFacade {
  constructor(
    private store: EnderecoTipoStore,
    private service: EnderecoTipoService
  ) {}
  get enderecoTipos(): EnderecoTipo[] {
    return this.store.enderecoTipos;
  }
  get enderecoTipos$(): Observable<EnderecoTipo[]> {
    return this.store.enderecoTipos$;
  }
  async buscarEnderecoTipos() {
    const enderecoTipos = await this.service.buscarTodos();
    this.store.adicionarRange(enderecoTipos);
  }
  limparListaEnderecoTipos() {
    this.store.enderecoTipos = null;
  }
}
