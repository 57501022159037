import { Pipe, PipeTransform } from '@angular/core';
import { EnderecoTipo } from '@app/abstraction/domain/interfaces/shared/endereco-tipo.interface';
import { EnderecoTipoFacade } from '@app/abstraction/domain/facades/shared/endereco-tipo.facade';

@Pipe({
  name: 'enderecoTipo',
})
export class EnderecoTipoPipe implements PipeTransform {
  constructor(private enderecoTipoFacade: EnderecoTipoFacade) {}
  transform(enderecoTipoId: string, campo: string = 'descricao'): any {
    if (enderecoTipoId) {
      const enderecoTipo = this.enderecoTipoFacade.enderecoTipos?.find(
        (enderecoTipo: EnderecoTipo) =>
          enderecoTipo.enderecoTipoId == enderecoTipoId
      ) as any;
      return enderecoTipo?.[campo];
    }
    return '';
  }
}
