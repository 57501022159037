import {AuthConfig} from 'angular-oauth2-oidc';
import {environment} from 'environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.authBaseUrl,
  clientId: environment.authConfig.clientId,
  postLogoutRedirectUri: environment.authConfig.postLogoutRedirectUri,
  redirectUri: environment.authConfig.redirectUri,
  responseType: environment.authConfig.responseType,
  scope: environment.authConfig.scope,
  oidc: environment.authConfig.oidc,
  showDebugInformation: environment.authConfig.showDebugInformation,
  strictDiscoveryDocumentValidation:
      environment.authConfig.strictDiscoveryDocumentValidation,
};
