<div
  class="cartao-endereco"
  [ngClass]="{ principal: endereco.principal }"
>
  <div class="icone"><i class="pi pi-map-marker"></i></div>
  <div class="info">
    <h4 class="text-right">
      <p-tag
        [value]="endereco.ativo ? 'Ativo' : 'Inativo'"
        [severity]="endereco.ativo ? 'success' : 'danger'"
      ></p-tag>
    </h4>
    <h3>{{ endereco.logradouro }}, {{ endereco.numero }}</h3>
    <h4>
      {{ endereco.bairro }}, {{ endereco.cidade }} -
      {{ endereco.ufIbge | estado }}
    </h4>
    <h4>{{ endereco.paisIbge | pais }}, {{ endereco.cep | cep }}</h4>
    </div>
  <div class="barra-opcoes cursor-pointer w-full">
    <span>{{ endereco.enderecoTipoId | enderecoTipo }}</span>
    <i
    class="pi pi-pencil ml-2 mr-2 float-right"
    tooltipPosition="top"
    pTooltip="Editar endereço"
    (click)="editarClicado()"
    ></i>
    <i
      class="pi pi-star float-right"
      *ngIf="endereco.principal"
      tooltipPosition="top"
      pTooltip="Endereço principal"
    ></i>
  </div>
</div>
