import { Injectable } from '@angular/core';
import { CepService } from '@app/abstraction/data/services/shared/cep.service';

@Injectable({ providedIn: 'root' })
export class CepFacade {
  constructor(private cepService: CepService) {}

  consultarCep(cep: string) {
    return this.cepService.consultarCep(cep);
  }
}
