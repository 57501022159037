import { Pipe, PipeTransform } from '@angular/core';
import { Estados } from '@app/shared/constants/estados.constant';

@Pipe({
  name: 'estado',
})
export class EstadoPipe implements PipeTransform {
  transform(ufIbge: number, campo: string = 'ufSigla') {
    if (ufIbge) {
      const estado = Estados.find((estado) => estado.ufIbge == ufIbge) as any;
      return estado?.[campo];
    }
    return '';
  }
}
