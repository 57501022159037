import { Pipe, PipeTransform } from '@angular/core';
import { Versao } from '@app/abstraction/domain/entities/versao/versao.entity';
import { VersaoFacade } from '@app/abstraction/domain/facades/cadastros/versao/versao.facade';
import { VersaoListagem } from '@app/abstraction/domain/interfaces/versao/versao-listagem.interface';

@Pipe({
  name: 'versao',
})
export class VersaoPipe implements PipeTransform {
  constructor(private versaoFacade: VersaoFacade) {}
  transform(id: string): any {
    if (id) {
      const versao = this.versaoFacade.versoes?.find(
        (versao: VersaoListagem) => versao.id == id
      ) as Versao;
      return `${versao.major}.${versao.minor}.${versao.patch}`;
    }
    return '';
  }
}
