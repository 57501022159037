import { Component, ContentChild, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UsuarioAuthFacade } from '@app/abstraction/domain/facades/shared/usuario-auth.facade';
import { BaseComponent } from '@app/shared/components/base/base.component';
import { FavoritoTipo } from '@app/shared/enums/favorito-tipo.enum';
import { Favorito } from '@app/shared/interfaces/components/favorito.interface';
import { ToastrService } from 'ngx-toastr';
import { Breadcrumb } from 'primeng/breadcrumb';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent extends BaseComponent implements OnInit {
  @ContentChild('breadcrumb') breadcrumb: Breadcrumb;
  @Input() title: string;
  @Input() showFavoriteButton: boolean;

  iconCss = new UntypedFormControl();
  form: UntypedFormGroup;
  checked: any;
  displayModal: boolean;

  constructor(
    private fb: UntypedFormBuilder,
    private usuarioFacade: UsuarioAuthFacade,
    private router: Router,
    toastrService: ToastrService
  ) {
    super(toastrService);
  }
  ngOnInit() {
    this.montarForm();
    this.verificarFavoritos();
  }
  verificarFavoritos() {
    if (this.usuarioFacade.usuarioLogado?.favoritos) {
      this.checked = this.usuarioFacade.usuarioLogado.favoritos.find(
        (e) => e.link === this.router.url
      );
    }
  }
  montarForm() {
    this.form = this.fb.group({
      descricao: [this.title],
      tipo: [FavoritoTipo.BarraFavoritos],
      link: [this.router.url],
      icone: [null],
    });
  }
  open() {
    this.montarForm();
    this.displayModal = true;
  }
  adicionar() {
    const favorito = Object.assign(<Favorito>{}, this.form.value as Favorito);
    favorito.tipo = Number(favorito.tipo);
    this.usuarioFacade.adicionarFavorito(favorito);
    this.checked = !this.checked;
    this.toastrService.success('Item adicionado com sucesso!!');
    this.displayModal = false;
  }
  remover() {
    if (this.usuarioFacade.usuarioLogado.favoritos) {
      const favorito = this.usuarioFacade.usuarioLogado.favoritos?.find(
        (e) => e.link === this.router.url
      );
      this.usuarioFacade.removerFavorito(favorito);
      this.checked = !this.checked;
      this.toastrService.success('Item removido com sucesso!!');
    }
  }
  selecionarIcone(icon: string): void {
    this.form.get('icone').setValue(icon);
  }
}
