import {Component, ContentChild, ElementRef, EventEmitter, Input, Output, TemplateRef,} from '@angular/core';
import {TableColumn} from '@app/shared/interfaces/components/table-column.interface';
import {TreeTable} from 'primeng/treetable';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
})
export class TableComponent {
  @ContentChild('rowExpanded') rowExpanded: TemplateRef<any>;
  tt: TreeTable;
  @Input() skeletonRowsCount = 10;
  @Input() checkboxable: boolean;
  @Input() columns: TableColumn[];
  @Input() dataKey: string;
  @Input() expandable: boolean;
  @Input() filterable: boolean;
  @Input() reorderableRows: boolean;
  @Input() rows: any[];
  @Input() selecteds: any[];
  @Input() selectable: boolean;
  @Input() treeTable: boolean;
  @Input() selectionMode: 'single'|'multiple' = 'single';
  @Input() styleClass: string;
  @Input() emptyMessage: string = 'Nenhum item encontrado';
  @Output() selectedsChange = new EventEmitter<any[]>();
  @Output() aoExpandir = new EventEmitter<any>();
  @Output() aoReordenar = new EventEmitter<any>();
  @Output() aoSelecionar = new EventEmitter<any[]>();

  get shownedColumns() {
    return this.columns?.filter((e) => !e.hidden);
  }
  get colspan() {
    let count = this.columns?.filter((e) => !e.hidden).length;
    if (this.reorderableRows) {
      count++;
    }
    if (this.expandable) {
      count++;
    }
    if (this.checkboxable) {
      count++;
    }
    if (this.selectable) {
      count++;
    }
    return count;
  }
  onRowSelect() {
    this.aoSelecionar.emit(this.selecteds);
    this.selectedsChange.emit(this.selecteds);
  }
  onRowExpand(event: any) {
    this.aoExpandir.emit(event);
  }
  onRowReorder(event: any) {
    this.aoReordenar.emit(event);
  }
  checkFilterType(type: any, propType: any) {
    return type === propType;
  }
}
