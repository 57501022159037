<div *ngIf="!group">
  <p-multiSelect
    #contentInput
    selectedItemsLabel="{0} itens selecionadas"
    appendTo="body"
    styleClass="p-inputtext-md inputfield w-full"
    [options]="options"
    [placeholder]="placeholder"
    [optionLabel]="optionLabel"
    [optionValue]="optionValue"
    [emptyMessage]="emptyMessage"
    [emptyFilterMessage]="emptyFilterMessage"
    [readonly]="readonly"
    [showClear]="showClear"
    [filter]="showFilter"
    [display]="display"
    [ngClass]="{ readonly: readonly }"
    [ngModelOptions]="modelOptions"
    (ngModelChange)="onNgModelChange($event)"
    [(ngModel)]="model"
  >
  </p-multiSelect>
</div>
<div *ngIf="group">
  <p-multiSelect
    #contentInput
    selectedItemsLabel="{0} itens selecionadas"
    appendTo="body"
    styleClass="p-inputtext-md inputfield w-full"
    [options]="options"
    [placeholder]="placeholder"
    [emptyMessage]="emptyMessage"
    [emptyFilterMessage]="emptyFilterMessage"
    [readonly]="readonly"
    [showClear]="showClear"
    [filter]="showFilter"
    [display]="display"
    [ngClass]="{ readonly: readonly }"
    [ngModelOptions]="modelOptions"
    (ngModelChange)="onNgModelChange($event)"
    [(ngModel)]="model"
    [group]="group"
    [scrollHeight]="scrollHeight + 'px'"
  >
  </p-multiSelect>
</div>
